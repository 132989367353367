export const MenuElements = [
	{
		_id: 1000,
		description: 'Timbratore',
		path: '/Timbratore',
		element: 'Timbratore',
		auth: 0,
		requireUser: 'employee',
	},
	{
		_id: 2000,
		description: 'Presenze',
		path: '/Presenze',
		element: 'Presenze',
		auth: 100,
		subMenu: [
			{
				_id: 2005,
				description: 'Cartellini',
				path: '/Cartellini',
				element: 'Cartellini',
				auth: 0,
			},
			{
				_id: 2010,
				description: 'Dipendenti',
				path: '/Dipendenti',
				element: 'Dipendenti',
				auth: 0,
			},
			{
				_id: 2900,
				description: 'Impostazioni',
				path: '/PresenzeSetups',
				element: 'PresenzeSetups',
				auth: 0,
			},
		],
	},
	{
		_id: 3000,
		description: 'Cantieri',
		path: '/Cantieri',
		element: 'Cantieri',
		auth: 0,
		subMenu: [],
	},
	{
		_id: 3010,
		description: 'SitePage',
		path: '/Cantieri/:site',
		element: 'SitePage',
		auth: 0,
		menu: false,
	},
	{
		_id: 3010,
		description: 'Report',
		path: '/Cantieri/:site/report/:report',
		element: 'Report',
		auth: 0,
		menu: false,
	},

	{
		_id: 4000,
		description: 'Contatti',
		path: '/Contatti',
		element: 'Contatti',
		auth: 0,
		// subMenu: [{}],
	},
	{
		_id: 9000,
		description: 'Impostazioni',
		path: '/Impostazioni',
		element: 'Impostazioni',
		auth: 1000,
		subMenu: [
			{
				_id: 9010,
				description: 'Utenti',
				path: '/Utenti',
				element: 'Utenti',
				auth: 0,
			},
			{
				_id: 9020,
				description: 'Cantieri',
				path: '/CantieriSetup',
				element: 'CantieriSetup',
				auth: 0,
			},
			{
				_id: 9022,
				description: 'Contatti',
				path: '/ContattiSetup',
				element: 'ContattiSetup',
				auth: 0,
			},
			// 	{
			// 		_id: 9990,
			// 		description: 'Supervisione',
			// 		path: '/Supervisione',
			// 		element: 'Supervisione',
			// 		auth: 0,
			// 	},
		],
	},
];
