import React, { useState, useContext, useEffect } from 'react';

import classes from './ContactPanel.module.css';

import { ReportCxt } from '../../../../../../context/reportCxt';

import ContactCard from '../ContactCard/ContactCard';

function ContactPanel({ contact, updatePartecipants }) {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const [partecipants, setPartecipants] = useState(
		reportState?.reportHeader?.refPartecipants || []
	);
	const [visibility, setVisibility] = useState('noPrint');

	useEffect(() => {
		if (partecipants.length > 0) {
			partecipants.map(p => {
				if (p?.refContatto?.refCompany == contact?.refContatto?._id) {
					setVisibility('');
				}
			});
		}
	}, [partecipants]);
	return (
		<div className={`${classes.wrapper} ${classes[visibility]}`}>
			<div className={classes.header}>{contact?.refContatto?.fullname}</div>
			{contact.siteReferents?.length > 0 &&
				contact?.siteReferents?.map((c, i) => {
					return (
						<ContactCard
							key={`panel_${c?._id || i}`}
							contact={c}
							updatePartecipants={updatePartecipants}
						/>
					);
				})}
		</div>
	);
}

export default ContactPanel;
