import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Registrazioni.module.css';

import RegOpenLine from '../../Comps/RegOpenLine';
import RegOpenReport from './Comps/RegOpenReport';

function Registrazioni({ site, openReports, openActivities }) {
	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>Registrazioni</div>
			{openReports.length > 0 && (
				<div className={classes.reportWrapper}>
					<h3>Verbali in sospeso</h3>
					<div className={classes.reports}>
						{openReports?.map(r => {
							return <RegOpenReport key={r._id} site={site} report={r} />;
						})}
					</div>
				</div>
			)}

			{openActivities.length > 0 && (
				<div className={classes.linesWrapper}>
					<h3>Prescrizioni da verificare</h3>
					<div className={classes.lines}>
						{openActivities?.map(a => {
							if (a.refReportHeader.isClosed) {
								return <RegOpenLine key={a._id} line={a} />;
							}
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default Registrazioni;
