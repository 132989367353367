import React, { useState, useEffect } from 'react';

import classes from './ContattiCantiere.module.css';

import ContactSiteCard from './Comps/ContactSiteCard';
import FormAddSiteContact from './Comps/FomrAddSiteContact/FormAddSiteContact';

import IconButton from '../../../../utils/IconButton';
import Find from '../../../../utils/Inputs/Find';

function ContattiCantiere({ contacts, updateContacts, add }) {
	const [contactsList, setContactsList] = useState([]);
	const [contactsVisuals, setContactsVisuals] = useState(null);
	const [selectedContact, setSelectedContact] = useState(null);

	const [showAddButton, setShowAddButton] = useState(true);

	useEffect(() => {
		setContactsList(contacts);
	}, [contacts]);

	/**
	 * -------------------------------------
	 * Aggiungi elemento
	 * -------------------------------------
	 */

	const handleShowAddButton = () => {
		setShowAddButton(!showAddButton);
	};

	const postNewElement = el => {
		console.log('post');
		console.log(el);
		if (el) {
			setContactsList(prev => {
				const nList = [...prev, el];
				updateContacts(nList);
				return nList;
			});
		}

		handleShowAddButton();
		evalContactsVisuals();
	};

	const updateContactData = (id, referent) => {
		console.log('update');
		console.log(id);
		if (id) {
			console.log('here');
			setContactsList(prev => {
				const nList = prev.map(el => {
					console.log(el);
					if (el._id === id) {
						console.log({ referent });
						el.siteReferents = [...referent];
					}
					return el;
				});
				console.log({ nList });
				updateContacts(nList);
				return nList;
			});
		}

		evalContactsVisuals();
	};

	/**< Aggiungi elemento -------------------- */

	const evalContactsVisuals = () => {
		const _v = contactsList.map(c => {
			return (
				<ContactSiteCard
					key={c._id}
					contact={c}
					updateContact={updateContactData}
				/>
			);
		});

		setContactsVisuals(_v);
	};

	useEffect(() => {
		evalContactsVisuals();
	}, [contactsList]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>Soggetti coinvolti</div>
			<div className={classes.contWrapper}>{contactsVisuals}</div>
			<div className={classes.buttons}>
				{showAddButton ? (
					<IconButton
						text={'add_circle'}
						style={{ fontSize: '2rem', color: 'var(--activeLink)' }}
						action={handleShowAddButton}
					/>
				) : (
					<FormAddSiteContact
						postNewElement={postNewElement}
						url={'contacts/activeCompanysList'}
						subject='Azienda'
						contctsList={contactsList}
						roleUrl={`settings/sites/roles/list/Azienda`}
					/>
				)}
			</div>
		</div>
	);
}

export default ContattiCantiere;
