import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactDom from 'react-dom';

import classes from './ReportSignatures.module.css';

import { ReportCxt } from '../../../../../../context/reportCxt';
import FormSignature from '../../../../../../utils/Signature/FormSignature';
import AddGuest from './Guest/AddGuest';

import IconButton from '../../../../../../utils/IconButton';

import { useHttpClient } from '../../../../../../hooks/http-hooks';
import ErrorModal from '../../../../../../utils/ErrorModal';
import LoadingSpinner from '../../../../../../utils/LoadingSpinner';

function ReportSignatures() {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const [signaturesVisuals, setSignaturesVisuals] = useState(null);
	const guests = useRef(reportState.reportHeader.guests || []);

	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const evalSignVisuals = () => {
		const _v = reportState.reportHeader.refPartecipants.map(el => {
			return (
				<div key={el?.refContatto?._id} className={classes.signatureWrapper}>
					<p className={classes.role}>{el?.refSiteRole?.name || '-'}</p>
					<p className={classes.name}>{el?.refContatto?.fullname}</p>
					<div className={classes.sWrap}>
						{!reportState?.reportHeader?.isClosed && (
							<FormSignature
								width={'700px'}
								key={el?.refContatto?._id}
								id={el?.refContatto?._id}
								sign={el?.sign}
								updateSign={postSing}
							/>
						)}
					</div>
				</div>
			);
		});

		guests.current.map(g => {
			_v.push(
				<div key={g.idx} className={classes.signatureWrapper}>
					<p className={classes.role}>{g?.role || '-'}</p>
					<p className={classes.name}>{g?.fullname}</p>
					<div className={classes.sWrap}>
						{!reportState?.reportHeader?.isClosed && (
							<FormSignature
								width={'700px'}
								key={g.idx}
								id={g.idx}
								sign={g.sign}
								updateSign={postGuestSing}
							/>
						)}
					</div>
				</div>
			);
		});

		setSignaturesVisuals(_v);
	};

	useEffect(() => {
		console.log({ reportState });
		evalSignVisuals();
	}, [reportState]);

	/**
	 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	 * Salva firma
	 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	 */

	const postSing = s => {
		console.log({ s });

		const oldPartecipants = [...reportState.reportHeader.refPartecipants];

		const nPartecipants = oldPartecipants?.map(p => {
			if (p.refContatto._id == s.id) {
				p.sign = s.signatureData;
			}
			return p;
		});

		sendRequest(
			'report/edit',
			'POST',
			{
				rId: reportState.reportHeader._id,
				par: 'refPartecipants',
				value: nPartecipants,
			},
			{ 'Content-Type': 'application/json' }
		);
	};

	const postGuestSing = s => {
		const oldGuests = [...reportState.reportHeader.guests];

		const nGuests = oldGuests?.map(g => {
			if (g.idx == s.id) {
				g.sign = s.signatureData;
			}
			return g;
		});

		sendRequest(
			'report/edit',
			'POST',
			{
				rId: reportState.reportHeader._id,
				par: 'guests',
				value: nGuests,
			},
			{ 'Content-Type': 'application/json' }
		);
	};

	/** < Salva firma ---------------- */

	/**
	 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	 * Gestione firma ospiti
	 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	 */

	const [showGuestForm, setShowGuestForm] = useState(false);

	const handleOpenGuestForm = () => {
		setShowGuestForm(!showGuestForm);
	};

	const openGuestInputForm = () => {
		const form = <AddGuest clear={handleOpenGuestForm} action={addGuest} />;

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	const addGuest = guestData => {
		guests.current.push(guestData);

		updateReportSignatures({
			...reportState.reportHeader,
			guests: [...guests.current],
		});
	};

	const updateReportSignatures = async upData => {
		await rActions.updateReportHeader(upData);

		await sendRequest(
			'report/edit',
			'POST',
			{
				rId: upData._id,
				par: 'guests',
				value: upData.guests,
			},
			{ 'Content-Type': 'application/json' }
		);

		evalSignVisuals();
	};

	/** < Firma ospiti ---------------- */

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			{showGuestForm && openGuestInputForm()}
			<div className={classes.wrapper}>
				<div className={classes.signatures}>{signaturesVisuals}</div>
				<div className={classes.buttons}>
					{!reportState?.reportHeader?.isClosed && (
						<IconButton
							text={'add_circle'}
							style={{ fontSize: '2rem', color: 'var(--activeLink)' }}
							action={handleOpenGuestForm}
						/>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}

export default ReportSignatures;
