import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './RegOpenReport.module.css';
import { reportTypes, reportModels } from '../../../Report/ReportsData';

function RegOpenReport({ site, report }) {
	const creationDate = new Date(report.creationDate);
	let rModel;
	let rType;
	reportModels.map(m => {
		if (m.code === report.reportModel) {
			rModel = m.name;
			reportTypes.map(t => {
				if (m.type == t.code) {
					rType = t.name;
				}
			});
		}
	});

	return (
		<NavLink
			key={report._id}
			className={classes.reportCard}
			to={`report/${report._id}`}
			state={{ site: site }}
		>
			<p>Tipologia: {rType}</p>
			<p>Modello: {rModel}</p>
			<p>Creato il: {creationDate.toLocaleDateString('it-IT')}</p>
			<p>Ore: {creationDate.toLocaleTimeString('it-IT')}</p>
			<p>Da: {report.refCreatedBy.name}</p>
		</NavLink>
	);
}

export default RegOpenReport;
