import {
	VALIDATOR_REQUIRE,
	VALIDATOR_NO,
} from '../../../../../utils/validators';

export const siteFormInputs = (site, checkedHandler) => {
	let validity = false;

	if (site !== undefined) {
		validity = true;
	}

	return {
		name: {
			value: site?.name || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Nome Cantiere',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: validity,
			initValue: site?.name || '',
			// width: '30%',
		},

		address: {
			value: site?.address || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Indirizzo',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: validity,
			initValue: site?.address || '',
			// width: '30%',
		},
		city: {
			value: site?.city || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Città',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: validity,
			initValue: site?.city || '',
			// width: '30%',
		},
		county: {
			value: site?.county || '',
			isValid: true,
			el: 'input',
			type: 'text',
			label: 'Provincia',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: site?.county || '',
			// width: '30%',
		},
		country: {
			value: site?.country || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Nazione',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: validity,
			initValue: site?.country || '',
			// width: '30%',
		},
		ped: {
			value: site?.ped || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Particella edilizia',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: validity,
			initValue: site?.ped || '',
			// width: '30%',
		},
		descriptionSmall: {
			value: site?.descriptionSmall || true,
			isValid: true,
			el: 'textarea',
			type: 'textarea',
			label: 'Descrizione breve',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: site?.descriptionSmall || '',
			contStyle: { width: '100%' },
		},
		descriptionBig: {
			value: site?.descriptionBig || true,
			isValid: true,
			el: 'textarea',
			type: 'textarea',
			label: 'Descrizione',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: site?.descriptionBig || '',
			contStyle: { width: '100%' },
		},

		//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

		// currentFase: {
		// 	type: Schema.Types.ObjectId,
		// 	required: true,
		// 	ref: 'SiteFase',
		// },
		// stopped: { type: Boolean, default: false },
		// ended: { type: Boolean, default: false },

		//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

		// isActive: {
		// 	value: site?.isActive || true,
		// 	isValid: true,
		// 	el: 'checkbox',
		// 	type: 'checkbox',
		// 	label: 'Attivo',
		// 	validator: [VALIDATOR_NO()],
		// 	execute: () => checkedHandler('isActive'),
		// 	initIsValid: true,
		// 	initValue: site?.isActive || '',
		// 	defaultChecked: true,
		// },

		//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

		// coverImg: {
		// 	value: site?.country || '',
		// 	isValid: validity,
		// 	el: 'input',
		// 	type: 'text',
		// 	label: 'Link Immagine di copertina',
		// 	validator: [VALIDATOR_REQUIRE()],
		// 	initIsValid: validity,
		// 	initValue: site?.country || '',
		// 	width: '30%',
		// },
	};
};
