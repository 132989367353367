import React, { useState } from 'react';

import classes from './ContactSiteCard.module.css';

import ContactEmployeePanel from './ContactEmployeePanel/ContactEmployeePanel';

import Find from '../../../../../utils/Inputs/Find';
import IconButton from '../../../../../utils/IconButton';

function ContactSiteCard({ contact, updateContact }) {
	const [siteReferents, setSiteReferents] = useState(
		contact.siteReferents || []
	);
	const [showReferentSelection, setShowReferentSelection] = useState(null);
	const [selectedReferent, setSelectedReferent] = useState(null);
	const [selectedSiteRole, setSelectedSiteRole] = useState(null);

	const handleShowReferentSelection = () => {
		setShowReferentSelection(!showReferentSelection);
	};

	const addReferent = () => {
		console.log({ selectedReferent });

		setSiteReferents(prev => {
			const nReferents = [
				...prev,
				{
					refContatto: selectedReferent,
					refSiteRole: selectedSiteRole,
					isActive: true,
					sendAlwaysMail: true,
				},
			];
			updateContact(contact._id, nReferents);
			return nReferents;
		});

		abortSelection();
	};

	const abortSelection = () => {
		setSelectedReferent(null);
		setSelectedSiteRole(null);
		setShowReferentSelection(false);
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>{contact?.refContatto?.fullname}</div>
			<div className={classes.body}>
				<div className={classes.details}>
					<div className={classes.subtitle}>Info</div>
					<div>{contact?.sendAlwaysMail}</div>
					<div>{contact?.affidataria && <p>Azienda affidataria</p>}</div>
					<div>
						{contact?.subappaltatrice && (
							<div>
								Subappalto
								<p className={classes.appaltatore}>
									{contact?.appaltatore?.fullname}
								</p>
							</div>
						)}
					</div>
					Azienda attiva
					<div>
						{/* {contact?.signatureRequired && <p>Rileva presenza in verbale</p>} */}
					</div>
					{/* <div>{contact?.sendAlwaysMail && <p>Sempre in copia mail</p>}</div> */}
					<div>{contact?.description}</div>
				</div>
				<div className={classes.employees}>
					{contact?._id && (
						<ContactEmployeePanel
							siteReferents={siteReferents}
							showReferentSelection={showReferentSelection}
							contact={contact}
							selectedReferent={selectedReferent}
							setSelectedReferent={setSelectedReferent}
							selectedSiteRole={selectedSiteRole}
							setSelectedSiteRole={setSelectedSiteRole}
							addReferent={addReferent}
							abortSelection={abortSelection}
							handleShowReferentSelection={handleShowReferentSelection}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default ContactSiteCard;
