import React, { useState, useEffect, useContext } from 'react';

import classes from './FinalProvisions.module.css';

import { ReportCxt } from '../../../../../../context/reportCxt';

import { useForm } from '../../../../../../hooks/form-hook';
import { VALIDATOR_NO } from '../../../../../../utils/validators';

import { formatTextNewLine } from '../../../../../../lib/text';

import IconButton from '../../../../../../utils/IconButton';

function FinalProvisions({ update }) {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const [formState, inputHandler, setFormData, setInputs] = useForm({
		note: {
			value: reportState?.reportHeader?.finalProvisions || '',
			isValid: false,
			el: 'textarea',
			type: 'text',
			label: '',
			validator: [VALIDATOR_NO()],
			initValue: reportState?.reportHeader?.finalProvisions || '',
			initIsValid: false,
			rows: '20',
			width: '100%',
			placeholder: 'Eventuali disposizioni aggiuntive',
			errorText: 'hidden',
			style: {
				width: '100%',
				borderColor: 'var(--ENDED)',
				resize: 'vertical',
				height: 'auto',
			},
		},
	});

	const [needSave, setNeedSave] = useState(false);

	const [inputVisuals, setInputVisuals] = useState(setInputs(formState));

	const saveProvision = async () => {
		console.log('Salvatao ');
		const nText = formState.inputs.note.value;

		await rActions.updateReportHeader({
			...reportState.reportHeader,
			finalProvisions: nText,
		});

		const ok = await update(reportState.reportHeader._id, nText);

		ok && setNeedSave(false);
	};

	useEffect(() => {
		const value =
			formState.inputs.note.value != '' &&
			formState.inputs.note.value != reportState?.reportHeader?.finalProvisions;
		if (value && !needSave) {
			setNeedSave(true);
		}
	}, [formState]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.content}>{inputVisuals}</div>
			<div className={classes.contentPrint}>
				{formatTextNewLine(formState?.inputs?.note?.value, Date.now())}
			</div>
			<div className={`${classes.buttons} ${needSave ? '' : classes.disabled}`}>
				{!reportState?.reportHeader?.isClosed && (
					<IconButton
						text={'save'}
						action={saveProvision}
						disabled={!needSave}
					/>
				)}
			</div>
		</div>
	);
}

export default FinalProvisions;
