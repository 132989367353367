import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import classes from './ContactEmployeePanel.module.css';

import Find from '../../../../../../utils/Inputs/Find';
import IconButton from '../../../../../../utils/IconButton';

import ContactForm from '../../../../../Contatti/Comps/ContactForm';

function ContactEmployeePanel({
	siteReferents,
	showReferentSelection,
	contact,
	selectedReferent,
	setSelectedReferent,
	selectedSiteRole,
	setSelectedSiteRole,
	addReferent,
	abortSelection,
	handleShowReferentSelection,
}) {
	// console.log({ contact });
	/**
	 * >>>>>>>>>>>>>>>>>>>>>>>>>>>
	 * Inserimento nuovo contatto
	 * <<<<<<<<<<<<<<<<<<<<<<<<<<<
	 */
	const [addedContact, setAddedContact] = useState(null);
	const [showAddPerson, setShowAddPerson] = useState(null);

	const handelAddPerson = () => {
		setShowAddPerson(!showAddPerson);
	};

	const addNewPerson = () => {
		const form = (
			<ContactForm
				nType={'Persona'}
				clear={handelAddPerson}
				setContact={setAddedContact}
				company={contact?.refContatto}
				action={addInsertedContact}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	const addInsertedContact = data => {
		setSelectedReferent(data);
		handleShowReferentSelection();
	};

	/**
	 * <--- Inserimento nuovo contatto
	 */

	return (
		<React.Fragment>
			{showAddPerson && addNewPerson()}
			<div className={classes.employees}>
				<div className={classes.subtitle}>Referenti</div>
				{siteReferents?.length > 0 &&
					siteReferents.map(c => {
						const r = c?.refContatto;
						return (
							<div key={r?._id} className={classes.referentCard}>
								<div className={classes.data}>
									<h3>
										{r?.jobTitle} {r?.fullname}
									</h3>
									<p>{c?.refSiteRole?.name}</p>
								</div>
								<div className={classes.buttons}>
									{/* <IconButton text={'visibility'} /> */}
									{/* <IconButton key={'1'} text={'refresh'} action={} /> */}
								</div>
							</div>
						);
					})}
				{/* {siteReferents?.length < 1 && !showReferentSelection && (
				<div className={classes.error}>Selezionare un referente</div>
			)} */}
				{showReferentSelection && (
					<div className={classes.addReferentWrapper}>
						<div className={classes.findInputs}>
							{selectedReferent ? (
								<h3>{selectedReferent.fullname}</h3>
							) : (
								<Find
									url={`contacts/companyActivePersons/${contact?.refContatto?._id}`}
									setRes={setSelectedReferent}
									label={'Referente'}
									inputId={`id_Referente`}
									initialValue=''
									initValue=''
									driver={'fullname'}
									resName={null}
									isArray={true}
									contStyle={{ width: 'fit-content' }}
									style={{ fontWeight: 200 }}
									filter={siteReferents}
									filterParam={'refContatto'}
									filterParamChild={'_id'}
								/>
							)}
							{selectedReferent && (
								<Find
									url={`settings/sites/roles/list/Persona`}
									setRes={setSelectedSiteRole}
									label={'Ruolo'}
									inputId={`id_Referente`}
									initialValue={selectedSiteRole?.name}
									initValue={selectedSiteRole?.name}
									driver={'name'}
									resName={null}
									isArray={true}
									contStyle={{ width: 'fit-content' }}
									style={{ fontWeight: 200 }}
								/>
							)}
						</div>
						{selectedReferent && selectedSiteRole && (
							<IconButton
								text={'check_circle'}
								style={{
									fontSize: '1.5rem',
									color: selectedReferent && 'var(--ONGOING)',
								}}
								action={addReferent}
							/>
						)}
					</div>
				)}
				{showReferentSelection ? (
					<IconButton
						text={'cancel'}
						style={{ fontSize: '1.5rem', color: 'var(--STOPED)' }}
						action={abortSelection}
					/>
				) : (
					<div className={classes.addButtons}>
						<IconButton
							text={'add_circle'}
							action={handleShowReferentSelection}
						/>
						<IconButton text={'person_add'} action={handelAddPerson} />
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default ContactEmployeePanel;
