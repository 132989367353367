import React, { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';

import classes from './FormAddSiteContact.module.css';

import { useForm } from '../../../../../../hooks/form-hook';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_NO,
} from '../../../../../../utils/validators';
// import Input from '../../../../../../utils/Inputs/Input';
import Find from '../../../../../../utils/Inputs/Find';
import IconButton from '../../../../../../utils/IconButton';

import ContactForm from '../../../../../Contatti/Comps/ContactForm';

function FormAddSiteContact({
	postNewElement,
	url,
	roleUrl,
	subject,
	contctsList,
}) {
	const [selectedEl, setSelectedEl] = useState(null);
	const [selectedRelation, setSelectedRelation] = useState(null);
	const [selectedAppaltatore, setSelectedAppaltatore] = useState(null);
	const [selectedRole, setSelectedRole] = useState(null);

	const radioOpt = {
		aff: 'Affidataria',
		sub: 'Subappaltatrice',
	};

	const descriptionRef = useRef(null);

	const abortInsert = () => {
		postNewElement(null);
	};

	const postData = () => {
		console.log(formState.inputs.appaltatore.value);
		if (
			Boolean(selectedRelation === radioOpt.sub) &&
			!formState.inputs.appaltatore.value
		) {
			return window.alert(
				'Necessrio selezionare azienda responsabile del subappalto'
			);
		}

		const role = formState?.inputs?.refSiteRole?.value || null;

		const nContact = {
			refContatto: selectedEl,
			refSiteRole: selectedRole,
			affidataria: Boolean(selectedRelation === radioOpt.aff),
			subappaltatrice: Boolean(selectedRelation === radioOpt.sub),
			signatureRequired: Boolean(selectedRelation === radioOpt.aff), //todo:
			sendAlwaysMail: true, //todo:
			description: descriptionRef?.current?.value || '',
			active: true,
		};

		console.log(role);

		console.log(formState.inputs.appaltatore.value);

		const appaltatore = formState.inputs.appaltatore.value;
		if (appaltatore !== '') {
			console.log(appaltatore);
			const list = evalAppaltatoreList();
			list.map(c => {
				if (c.name === appaltatore) {
					nContact.appaltatore = { _id: c._id, fullname: c.name };
				}
			});
		}

		// console.log({ nContact });

		postNewElement(nContact);
	};

	const checkedHandler = id => {
		let _val = Boolean(document.getElementById(id).checked);
		inputHandler(id, _val, true);
		formState.inputs[id].value = _val;
	};

	const [formState, inputHandler, setFormData, setInputs] = useForm({
		relation: {
			value: selectedRelation,
			isValid: true,
			el: 'radio',
			type: 'radio',
			label: 'Tipo rapporto',
			validator: [VALIDATOR_REQUIRE()],
			list: [
				{ id: 0, value: 'relation_Affida', name: radioOpt.aff },
				{ id: 1, value: 'relation_Subappa', name: radioOpt.sub },
			],
			initIsValid: true,
			initValue: selectedRelation,
			setSelect: setSelectedRelation,
		},
		appaltatore: {
			value: selectedAppaltatore,
			isValid: true,
			el: 'dropdown',
			type: 'dropdown',
			label: 'Appaltatrore',
			list: [],
			validator: [VALIDATOR_REQUIRE()],
			initValue: selectedAppaltatore,
			setSelect: setSelectedAppaltatore,
			initIsValid: true,
			hide: Boolean(!selectedRelation === radioOpt.sub),
		},
	});

	const [formVisuals, setFormVisuals] = useState(setInputs(formState));

	const evalAppaltatoreList = () => {
		const ap_list = [];

		contctsList?.map(c => {
			if (c.affidataria) {
				const el = {};
				el.id = c.refContatto._id;
				el.key = c.refContatto._id;
				el._id = c.refContatto._id;
				el.name = c.refContatto.fullname;

				ap_list.push(el);
			}
		});

		return ap_list;
	};

	useEffect(() => {
		const nState = { ...formState };
		const i = nState.inputs;
		const keys = Object.keys(i);

		keys.map(k => {
			let _value = '';
			let _isValid = false;
			switch (k) {
				case 'relation':
					_value = selectedRelation;
					_isValid = true;
					break;

				case 'appaltatore':
					const hidden = !Boolean(selectedRelation === 'Subappaltatrice');
					i[k].hide = hidden;
					if (selectedRelation === 'Subappaltatrice') {
						i[k].list = evalAppaltatoreList();
					}
					_value = nState[k]?.value;
					_isValid = true;
					break;

				default:
					_value = nState[k]?.value;
					_isValid = Boolean(nState[k]?.isValid);
					break;
			}

			i[k].initValue = _value;
			i[k].value = _value;
			i[k].initIsValid = _isValid;
			i[k].isValid = _isValid;
		});

		setFormVisuals(setInputs(nState));
	}, [selectedRelation]);

	const [contactsFilter, setContactsFilter] = useState(
		contctsList?.map(c => {
			return c.refContatto;
		})
	);

	const evalContactsFilter = () => {
		// console.log('here');
		const filter = contctsList?.map(c => {
			return c.refContatto;
		});

		setContactsFilter(filter);
	};

	/**
	 * >>>>>>>>>>>>>>>>>>>>>>>>>
	 * Inerimento nuova azienda
	 * <<<<<<<<<<<<<<<<<<<<<<<<<
	 */

	const [nComp, setNComp] = useState(null);
	const [showAddCompany, setShowAddCompany] = useState(null);

	const handleShowAddCompany = () => {
		setShowAddCompany(!showAddCompany);
	};

	const addNewCompany = () => {
		const form = (
			<ContactForm
				nType={'Azienda'}
				clear={handleShowAddCompany}
				setContact={setNComp}
				action={handleNewCompany}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	const handleNewCompany = (nCompany = null, isNew) => {
		if (nCompany !== null) {
		}
		const datalist = document.getElementById('Aggiungi AziendaList');

		const nOption = document.createElement('option');
		nOption.value = nCompany.fullname;
		nOption.selected = true;

		datalist.appendChild(nOption);
		console.log('here');
		setNComp(nCompany);
	};

	/** <--- Inserimento nuova azienda */

	useEffect(() => {
		if (nComp) {
			setSelectedEl(nComp);
		}
	}, [nComp]);

	useEffect(() => {
		evalContactsFilter();
	}, []);

	return (
		<React.Fragment>
			{showAddCompany && addNewCompany()}
			<div className={classes.wrapper}>
				{selectedEl ? (
					<div className={classes.header} onClick={() => setSelectedEl(null)}>
						<IconButton text={'cancel'} />
						<p>{selectedEl.fullname}</p>
					</div>
				) : (
					<div className={classes.findCompanyWrapper}>
						<Find
							url={url}
							setRes={setSelectedEl}
							label={`Aggiungi ${subject}`}
							inputId={`id_newContact_${subject}`}
							initialValue={selectedEl?.fullname || ''}
							initValue={selectedEl || ''}
							driver={'fullname'}
							resName={null}
							isArray={true}
							contStyle={{ width: 'fit-content' }}
							style={{ fontWeight: 200 }}
							filter={contactsFilter}
							filterParam={'_id'}
						/>
						<IconButton
							text={'add_business'}
							style={{
								fontSize: '2rem',
								color: 'var(--activeLink)',
							}}
							action={handleShowAddCompany}
						/>
					</div>
				)}
				{selectedEl && (
					<div className={classes.body}>
						{formVisuals}
						<Find
							url={roleUrl}
							setRes={setSelectedRole}
							label={`Ruolo sul cantiere`}
							inputId={`id_siteRole_${subject}`}
							initialValue=''
							initValue=''
							driver={'name'}
							resName={null}
							isArray={true}
							contStyle={{ width: 'fit-content' }}
							style={{ fontWeight: 200 }}
						/>
					</div>
				)}
				{selectedEl && (
					<div className={classes.descriptionWrapper}>
						<label className={classes.descLabel}>
							Descrizione attività su cantiere
						</label>
						<textarea
							className={classes.description}
							rows={'10'}
							ref={descriptionRef}
						/>
					</div>
				)}
				{selectedEl &&
				selectedRelation &&
				selectedRole &&
				formState?.isValid ? (
					selectedRelation === radioOpt.sub &&
					!formState.inputs.appaltatore.value ? (
						<IconButton
							text={'cancel'}
							style={{ fontSize: '2rem', color: 'var(--STOPED)' }}
							action={abortInsert}
						/>
					) : (
						<IconButton
							text={'check_circle'}
							style={{ fontSize: '2rem', color: 'var(--ONGOING)' }}
							action={postData}
						/>
					)
				) : (
					<IconButton
						text={'cancel'}
						style={{ fontSize: '2rem', color: 'var(--STOPED)' }}
						action={abortInsert}
					/>
				)}
			</div>
		</React.Fragment>
	);
}

export default FormAddSiteContact;
