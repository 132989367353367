export const reportTypes = [
	{
		code: 'Site',
		name: 'Cantiere',
	},
	// {
	// 	code: 'Meeting',
	// 	name: 'Riunione',
	// },
];

export const reportModels = [
	{
		type: 'Site',
		code: 'ExctSafety',
		name: 'Sicurezza',
	},
	// {
	// 	type: 'Site',
	// 	code: 'MngtWorks',
	// 	name: 'Direzione lavori',
	// },
	// {
	// 	type: 'Meeting',
	// 	code: 'MngtWorks',
	// 	name: 'Porgettazione',
	// },
];
