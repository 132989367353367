import { useCallback, useReducer } from 'react';

const actions = {
	R_update: 'R_update',
	RH_update: 'RH_update',
	RL_set: 'RL_set',
	S_set: 'S_set',
	L_add: 'L_add',
	L_remove: 'L_remove',
};

const reportReducer = (state, action) => {
	switch (action.type) {
		//Imposta dati testata report
		case actions.RH_update: {
			return { ...state, reportHeader: action.value };
		}

		//Imposta dati righe report
		case actions.RL_set:
			return { ...state, reportLines: action.value };

		//Imposta dati cantiere
		case actions.S_set:
			return { ...state, site: action.value };

		// Aggiorna singolo elemento
		case actions.R_update:
			const test = state;

			if (
				test[action.section] &&
				test[action.section][action.parameter] !== undefined
			) {
				if (typeof action.value == 'object') {
					if (Array.isArray(action.value)) {
						test[action.section][action.parameter] = [...action.value];
					} else {
						test[action.section][action.parameter] = { ...action.value };
					}
				}
				test[action.section][action.parameter] = action.value;
			}

			return { ...test };

		// Inserisci nuova riga report
		case actions.L_add: {
			state.reportLines.push(action.value);

			return { ...state };
		}

		// Rimuovi riga report
		case actions.L_remove: {
			const updatedList = state.reportLines.filter(
				line => line._id !== action.id
			);
			state.reportLines = [...updatedList];
			return { ...state };
		}

		default:
			return { ...state };
	}
};

export const useReportHooks = initValue => {
	const [reportState, dispatch] = useReducer(reportReducer, initValue);
	const acts = {};

	acts.updateReportHeader = useCallback(value => {
		dispatch({ type: actions.RH_update, value: value });
	}, []);

	acts.updateReportState = useCallback((section, parameter, value) => {
		dispatch({ type: actions.R_update, section, parameter, value });
	}, []);

	acts.updateReportLines = useCallback(value => {
		dispatch({ type: actions.RL_set, value });
	}, []);

	acts.addReportLine = useCallback(value => {
		dispatch({ type: actions.L_add, value });
	}, []);

	acts.addState = useCallback(value => {
		dispatch({ type: actions.S_set, value });
	});

	acts.removeReportLine = useCallback(id => {
		dispatch({ type: actions.L_remove, id });
	});

	return { reportState, ...acts };
};
