import React from 'react';

import { mainVariables } from '../../../hooks/mainVariables';

import classes from './SiteCard.module.css';

// import IconButton from '../../../utils/IconButton';

function SiteCard({ site }) {
	return (
		<div className={classes.wrapper}>
			<div className={classes.image}>
				<img src={mainVariables.srv + 'images/' + site.coverImg} alt='Cover' />
			</div>

			<div className={classes.data}>
				<p className={classes.siteName}>{site.name}</p>
				<p className={classes.committente}>
					{site.committente.refContatto.fullname}
				</p>
				<p>
					{site.address} - {site.city} - ({site.county}){' '}
				</p>
				<p className={classes.description}>{site.descriptionSmall}</p>
			</div>
			{/* <div className={classes.buttons}>
				<IconButton text={'edit'} />
				<IconButton
					text={'post_add'}
					// style={{
					// 	fontSize: '2rem',
					// 	color: 'var(--activeLink)',
					// 	backgroundColor: 'var(--contrast)',
					// 	borderRadious: '50px',
					// 	border: '1px solid var(--text)',
					// 	padding: '0.6rem',
					// }}
				/>
			</div> */}
		</div>
	);
}

export default SiteCard;
