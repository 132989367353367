import React from 'react';

import { mainVariables } from '../../../../hooks/mainVariables';

import classes from './DatiCantiere.module.css';

import { formatTextNewLine } from '../../../../lib/text';

import IconButton from '../../../../utils/IconButton';

function DatiCantiere({ data, action }) {
	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>DatiCantiere</div>
			<div className={classes.dataWrapper}>
				{data && (
					<div className={classes.siteDataWrapper}>
						<div className={classes.siteData}>
							<div className={classes.data}>
								<p className={classes.siteName}>{data.name}</p>
								<p>
									{data.address} - {data.city} - ({data.county})
								</p>
								<p>{data.country}</p>
								<p>PED: {data.ped}</p>
							</div>
							<div>
								<p>Fase cantiere:</p>
								<i>Scelta fra quelli creati in setup</i>
							</div>
							<div>Stato: Attivo / Sospeso / Terminato</div>
							<div className={classes.description}>
								<p className={classes.title}>Riassunto</p>
								<div>{formatTextNewLine(data.descriptionSmall)}</div>
							</div>
							<div className={classes.description}>
								<p className={classes.title}>Descrizione</p>
								<div>{formatTextNewLine(data.descriptionBig)}</div>
							</div>
						</div>
						<div className={classes.imgWrapper}>
							<div className={classes.image}>
								<img
									id='siteCover'
									src={`${mainVariables.srv}images/${data.coverImg}`}
								/>
							</div>
						</div>
					</div>
				)}

				<div className={classes.buttons}>
					<IconButton
						text={data ? 'edit' : 'add_circle'}
						style={{ fontSize: '2rem', color: 'var(--activeLink)' }}
						action={action}
					/>
				</div>
			</div>
		</div>
	);
}

export default DatiCantiere;
