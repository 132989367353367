import React, { useEffect, useState } from 'react';

import classes from './Committente.module.css';

import { useForm } from '../../../../hooks/form-hook';
import { VALIDATOR_NO } from '../../../../utils/validators';

import Find from '../../../../utils/Inputs/Find';
import IconButton from '../../../../utils/IconButton';

function Committente({
	target,
	subject,
	parent,
	sourceUrl,
	setResult,
	newParam,
}) {
	const [newTarget, setNewTarget] = useState(null);
	const [showFind, setShowFind] = useState(newParam === 'new' ? true : false);

	/**
	 * --------------------------------------
	 * Gestione form
	 * --------------------------------------
	 */

	const checkedHandler = id => {
		let _val = Boolean(document.getElementById(id).checked);
		inputHandler(id, _val, true);
		formState.inputs[id].value = _val;
		setResult(prev => {
			return { ...prev, sendAlwaysMail: _val };
		});
	};

	const evalCheckValue = () => {
		// return Boolean(target?.sendAlwaysMail);
		if (newParam === 'new') {
			return true;
		} else {
			return target?.sendAlwaysMail;
		}
	};

	const [formState, inputHandler, setFormData, setInputs] = useForm({
		[`sendAlwaysMail_${subject}`]: {
			value: evalCheckValue(),
			isValid: true,
			el: 'checkbox',
			type: 'checkbox',
			label: 'Sempre in copia mail',
			validator: [VALIDATOR_NO()],
			execute: () => checkedHandler(`sendAlwaysMail_${subject}`),
			initIsValid: true,
			initValue: evalCheckValue(),
			defaultChecked: evalCheckValue(),
			errorText: 'hidden',
			contStyle: {
				width: 'fit-content',
				fontWeight: '200',
				flexDirection: 'row-reverse',
				gap: '1rem',
			},
		},
	});

	const [inputsVisuals, setInputsVisuals] = useState(setInputs(formState));

	/** << --------------------------------- */

	const resetData = () => {
		setShowFind(true);
		setNewTarget(null);
		setResult(null);
	};

	const confirmData = () => {
		setResult({
			refContatto: { ...newTarget },
			sendAlwaysMail: Boolean(
				formState.inputs[`sendAlwaysMail_${subject}`].value
			),
		});
	};

	useEffect(() => {
		let i = document.getElementById(`sendAlwaysMail_${subject}`);
		if (i) {
			i.checked = target?.sendAlwaysMail;
		}
	}, [target]);

	if (target) {
		return (
			<div className={classes.wrapper}>
				<div className={classes.header}>{subject}</div>

				<div className={classes.target}>
					<div className={classes.targetData}>
						<p className={classes.targetName}>{target.refContatto.fullname}</p>
						<p>
							{target.refContatto.address} - {target.refContatto.city} - (
							{target.refContatto.county})
						</p>
						<p>Tel: {target.refContatto.phoneNumber}</p>
						<p>Mail: {target.refContatto.email}</p>
						{inputsVisuals} - {target.sendAlwaysMail.toString()}
					</div>
					<div className={classes.buttons}>
						<IconButton
							text={'edit'}
							style={{ fontSize: '1.5rem', color: 'var(--activeLink)' }}
							action={resetData}
						/>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={classes.wrapper}>
				<div className={classes.addElement}>
					{(newParam === 'new' || showFind || parent) && (
						<Find
							url={sourceUrl}
							setRes={setNewTarget}
							label={subject}
							inputId={`id_${subject}`}
							initialValue=''
							initValue=''
							driver={'fullname'}
							resName={null}
							isArray={true}
							contStyle={{ width: 'fit-content' }}
							style={{ fontWeight: 200 }}
						/>
					)}
					{newTarget && (
						<IconButton
							text={'check_circle'}
							style={{ fontSize: '2rem', color: 'var(--ONGOING)' }}
							action={confirmData}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default Committente;
