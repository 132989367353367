import { useCallback, useReducer } from 'react';

import Input from '../utils/Inputs/Input';

const formReducer = (state, action) => {
	switch (action.type) {
		case 'INPUT-CHANGE':
			let formIsValid = true;
			for (const inputId in state.inputs) {
				if (inputId === action.inputId) {
					formIsValid = formIsValid && action.isValid;
				} else {
					formIsValid = formIsValid && state.inputs[inputId].isValid;
				}
			}
			return {
				inputs: {
					...state.inputs,
					[action.inputId]: {
						...state.inputs[action.inputId],
						value: action.value,
						isValid: action.isValid,
						// ...action,
					},
				},
				isValid: formIsValid,
			};

		case 'SET-DATA':
			return {
				inputs: action.inputs,
				isValid: action.formIsValid,
				// ...action,
			};
		default:
			return state;
	}
};

export const useForm = (initialInputs, initialFormValidity) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: initialInputs,
		isValid: initialFormValidity,
	});

	const inputHandler = useCallback((id, value, isValid) => {
		// console.log({ id });
		// console.log({ value });
		// console.log({ isValid });
		dispatch({
			type: 'INPUT-CHANGE',
			value: value,
			isValid: isValid,
			inputId: id,
		});
	}, []);

	const setFormData = useCallback((inputData, formValiduty) => {
		// console.log({ inputData });
		// console.log({ formValiduty });
		dispatch({
			type: 'SET-DATA',
			inputs: inputData,
			formIsValid: formValiduty,
		});
	}, []);

	const setInputs = data => {
		let inputs = data.inputs;
		let keys = Object.keys(data.inputs);

		const _v = keys.map(k => {
			let i = inputs[k];
			const idx = new Date().getTime();

			return (
				<Input
					key={`${idx}_${k}`}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
					name={i.name}
					list={i.list}
					width={i.width || 'auto'}
					style={{ ...i.style, padding: '0.5rem' }}
					setSelect={i.setSelect}
					execute={i.execute}
					defaultChecked={Boolean(i.defaultChecked)}
					rows={i.rows}
					hide={i.hide}
					contStyle={i.contStyle}
					placeholder={i.placeholder}
				/>
			);
		});
		return _v;
	};
	return [formState, inputHandler, setFormData, setInputs];
};
