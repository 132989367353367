import React, { useContext, useState } from 'react';
import ReactDom from 'react-dom';

import { mainVariables } from '../../../../../../hooks/mainVariables';
import { formatTextNewLine } from '../../../../../../lib/text';

import classes from './ReportLine.module.css';

import { ReportCxt } from '../../../../../../context/reportCxt';

import IconButton from '../../../../../../utils/IconButton';
import EditRecord from '../../Inspection/ExctSafety/ActiveWorks/EditRecord/EditRecord';

function ReportLine({ lineData, deleteReportLine }) {
	const { reportState, ...rActions } = useContext(ReportCxt);

	const deleteLine = () => {
		deleteReportLine(lineData._id);
	};

	/**------------------------------------------------ */
	/**Modifica dati linea ---------------------------- */
	/**------------------------------------------------ */

	const [lineIsEditing, setLineIsEditing] = useState(false);
	const handleEditLine = () => {
		setLineIsEditing(!lineIsEditing);
	};

	const editLine = () => {
		console.log('Modifica');
		handleEditLine();
	};

	const openEditLine = () => {
		const form = <EditRecord lineData={lineData} clear={handleEditLine} />;

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};
	/**------------------------------------------------ */

	return (
		<React.Fragment>
			{lineIsEditing && openEditLine()}
			<div className={classes.wrapper}>
				{lineData.imageUrl && (
					<div className={`${classes.imageWrapper}`}>
						<div
							className={`${classes.image} ${
								!lineData.imageUrl && classes.hidden
							}`}
						>
							<img src={mainVariables.srv + 'images/' + lineData.imageUrl} />
						</div>
					</div>
				)}
				<div
					className={`${classes.textWrapper} ${
						lineData.imageUrl ? 'pp' : classes.noPicture
					}`}
				>
					{lineData?.observation && (
						<div className={classes.observation}>
							{/* <p className={classes.lineHeader}>Osservazione</p> */}
							<div>
								{formatTextNewLine(lineData?.observation, 'ob' + lineData._id)}
							</div>
						</div>
					)}
					{lineData?.prescription && (
						<div className={classes.prescription}>
							<p className={classes.lineHeader}>Prescrizione</p>
							<div>
								{formatTextNewLine(lineData?.prescription, 'pr' + lineData._id)}
							</div>
						</div>
					)}
					{lineData.dueDate && (
						<div className={classes.revison}>
							<span>Si richiede applicazione entro il: </span>
							<span className={classes.revisionDate}>
								{new Date(lineData.dueDate).toLocaleDateString('it-IT', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
								})}
							</span>
						</div>
					)}
					{!reportState?.reportHeader?.isClosed && (
						<div className={classes.buttons}>
							<IconButton
								text={'edit'}
								style={{ color: 'var(--TODO)', padding: '0' }}
								action={editLine}
							/>
							<IconButton
								text={'delete'}
								style={{ color: 'var(--STOPED)', padding: '0' }}
								action={deleteLine}
							/>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}

export default ReportLine;
