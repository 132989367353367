import React, { useContext } from 'react';

import classes from './SiteMainData.module.css';

import { ReportCxt } from '../../../../../../context/reportCxt';

import Report_SiteContactCard from './Report_SiteContactCard/Report_SiteContactCard';
import Report_SiteContactPanel from './Report_SiteContactPanel/Report_SiteContactPanel';

function SiteMainData() {
	const { reportState, ...rActions } = useContext(ReportCxt);

	const evalCompanyCards = () => {
		const visuals = reportState?.site?.contacts?.map(c => {
			return <Report_SiteContactPanel key={'SCP_' + c._id} c={c} />;
		});

		return visuals;
	};

	return (
		<React.Fragment>
			<div className={classes.wrapper}>
				<div className={`${classes.committenteWrapper}`}>
					<div className={classes.comCompany}>
						<p className={classes.segmentTitle}>committente</p>
						<Report_SiteContactCard
							contact={reportState?.site?.committente?.refContatto}
						/>
					</div>

					<div>
						<p className={classes.segmentTitle}>referente</p>
						<Report_SiteContactCard
							contact={reportState?.site?.contactPerson?.refContatto}
						/>
					</div>
				</div>
				<div className={classes.contactsWrapper}>
					<p className={classes.segmentTitle}>Aziende</p>
					{evalCompanyCards()}
				</div>
			</div>
		</React.Fragment>
	);
}

export default SiteMainData;
