import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import classes from './SiteRoles.module.css';

import IconButton from '../../../../utils/IconButton';

import SiteEditForm from './SiteEditForm';

function SiteRoles({ list, postData }) {
	const [roleVisuals, setRoleVisuals] = useState(null);
	const [selectedRefRole, setSelectedRefRole] = useState(null);

	const addNewRole = () => {
		setSelectedRefRole(-1);
	};

	useEffect(() => {
		if (selectedRefRole) {
			handleShowEditForm();
		}
	}, [selectedRefRole]);

	const [showEditForm, setShowEditForm] = useState(false);
	const handleShowEditForm = () => {
		if (showEditForm) {
			setSelectedRefRole(null);
		}
		setShowEditForm(!showEditForm);
	};

	const openEditForm = () => {
		const form = (
			<SiteEditForm
				data={selectedRefRole}
				action={postData}
				clear={handleShowEditForm}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	const evalRoleVisuals = () => {
		// console.log(list);
		let lastType = '';
		const _v = [];
		list?.map(l => {
			if (lastType !== l.forPerson) {
				lastType = l.forPerson;
				if (l.forPerson) {
					_v.push(<div className={classes.subTitle}>PERSONA</div>);
				}
				if (l.forCompany) {
					_v.push(<div className={classes.subTitle}>AZIENDA</div>);
				}
			}
			_v.push(
				<div className={classes.card} onClick={() => setSelectedRefRole(l)}>
					<IconButton text={'edit'} />
					<div key={l._id} className={classes.data}>
						<p className={classes.cardTitle}>{l.name}</p>
						<p>{l.description}</p>
					</div>
				</div>
			);
		});

		setRoleVisuals(_v);
	};

	useEffect(() => {
		console.log('here');
		evalRoleVisuals();
	}, [list]);

	return (
		<React.Fragment>
			{showEditForm && openEditForm()}
			<div className={classes.wrapper}>
				<div className={classes.header}>
					Ruoli
					<div className={`${classes.header__add}`}>
						<IconButton text={'add_circle'} action={addNewRole} />
					</div>
				</div>
				<div className={classes.roleWrapper}>{roleVisuals}</div>
			</div>
		</React.Fragment>
	);
}

export default SiteRoles;
