import React, { useState, useEffect } from 'react';

import classes from './Signature.module.css';

import { draw, clearCanvas } from '../../lib/drawCanvas';

import Button from '../Button/Button';

function Signature({ setValue, width, height, target, clear }) {
	const cId = 'sign-area';

	useEffect(() => {
		if (window.screen.width < 900) {
			let doc = document.documentElement;
			//Chrome
			if (doc.requestFullscreen) {
				doc.requestFullscreen();
			} else if (doc.webkitRequestFullscreen) {
				//SAFARI
				doc.webkitRequestFullscreen();
			} else if (doc.msRequestFullscreen) {
				//IE 11
				doc.msRequestFullscreen();
			}
			window.screen.orientation.lock('landscape');
		}
		draw(cId, 'black', 1);
	}, []);

	const closeForm = () => {
		window.screen.orientation.unlock();
		if (window.screen.width < 900) {
			let doc = document;
			//Chrome
			if (doc.exitFullscreen) {
				doc.exitFullscreen();
			} else if (doc.webkitExitFullscreen) {
				//SAFARI
				doc.webkitExitFullscreen();
			} else if (doc.msExitFullscreen) {
				//IE 11
				doc.msExitFullscreen();
			}
		}
		clear();
	};

	const saveSign = () => {
		const canvas = document.getElementById(cId);
		const dataUrl = canvas.toDataURL();

		setValue(dataUrl);
		// const sigImage = document.getElementById(target);
		// sigImage.setAttribute('src', dataUrl);
		closeForm();
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={closeForm}></div>
			<div className={classes.wrapper}>
				<div className={classes.cWrapper}>
					<canvas
						id={cId}
						width={width}
						height={height}
						className={classes.canvas}
					></canvas>
				</div>
				<div className={classes.buttons}>
					<Button clname='confirm' onClick={saveSign}>
						Salva
					</Button>
					<Button clname='reverse' onClick={() => clearCanvas(cId)}>
						Cancella
					</Button>
					<Button clname='danger' onClick={closeForm}>
						Chiudi
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Signature;
