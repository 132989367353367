import React, { useState, useEffect } from 'react';

import classes from './EditUser.module.css';

import { MenuElements } from '../../../../__data/common';

import { useHttpClient } from '../../../../hooks/http-hooks';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import ErrorModal from '../../../../utils/ErrorModal';

import { useForm } from '../../../../hooks/form-hook';
import { VALIDATOR_NO } from '../../../../utils/validators';
import Input from '../../../../utils/Inputs/Input';
import Button from '../../../../utils/Button/Button';

function EditUser({ user, clear }) {
	/**
	 * ----------------------------
	 * Autorizzazioni utente
	 * ----------------------------
	 */
	const [formElements, setFormElements] = useState('');
	const [activeElements, setActiveElements] = useState(
		[...user?.authorizations] || []
	);

	const evalFormElements = () => {
		const elements = {};
		MenuElements.map(el => {
			if (el.menu === false) {
				return;
			}
			return (elements[el.description] = {
				value: '',
				isValid: false,
			});
		});

		return elements;
	};

	const [formState, inputHandler, setFormData] = useForm(evalFormElements());

	const checkedHandler = id => {
		let _val = document.getElementById(id).checked;
		inputHandler(id, _val, true);
		if (activeElements.indexOf(id) >= 0) {
			setActiveElements(prev => {
				return prev.filter(el => {
					return el !== id;
				});
			});
		} else {
			setActiveElements(prev => {
				return [...prev, id];
			});
		}
	};

	useEffect(() => {
		let keys = Object.keys(formState.inputs);

		user.authorizations = [...activeElements];

		const inputsVisual = [];
		keys.map(k => {
			let active = activeElements.includes(k);
			inputsVisual.push(
				<div className={`${classes.input} ${active && classes.activeInput}`}>
					<Input
						key={k}
						id={k}
						element={'checkbox'}
						type={'checkbox'}
						label={k}
						validators={[VALIDATOR_NO()]}
						onInput={inputHandler}
						initValue={active}
						initIsValid={true}
						execute={() => checkedHandler(k)}
						width='auto'
					/>
				</div>
			);
		});

		setFormElements(inputsVisual);
	}, [activeElements]);
	/**
	 * ----------------------------
	 * Invia dati aggiornati
	 * ----------------------------
	 */

	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const saveData = async () => {
		console.log(activeElements);
		user.authorizations = [...activeElements];
		await sendRequest(
			'authentication/editUser',
			'POST',
			{ userId: user._id, authorizations: activeElements },
			{
				'Content-Type': 'application/json',
			}
		);
		clear();
	};

	/**
	 * ----------------------------
	 */

	return (
		<React.Fragment>
			{isLoading && <LoadingSpinner asOverlay />}
			{error && <ErrorModal error={error} onClear={clearError} />}
			<div className={classes.background} onClick={clear} />
			<div className={classes.wrapper}>
				<h1>{user.name}</h1>
				<div className={classes.authCard}>
					Autorizzazioni:
					<div className={`${classes.element} ${classes.authList}`}>
						{formElements}
					</div>
				</div>
				<div className={classes.buttons}>
					<Button clname='danger big' action={clear}>
						Chiudi
					</Button>
					<Button clname='confirm big' action={saveData}>
						Salva
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default EditUser;
