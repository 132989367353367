import React, { useContext, useState } from 'react';

import { mainVariables } from '../../../../../../../../hooks/mainVariables';

import classes from './NewRecord.module.css';

import IconButton from '../../../../../../../../utils/IconButton';

import { ReportCxt } from '../../../../../../../../context/reportCxt';

import { useForm } from '../../../../../../../../hooks/form-hook';
import {
	VALIDATOR_NO,
	VALIDATOR_REQUIRE,
} from '../../../../../../../../utils/validators';
import Find from '../../../../../../../../utils/Inputs/Find';

import { useHttpClient } from '../../../../../../../../hooks/http-hooks';
import ErrorModal from '../../../../../../../../utils/ErrorModal';
import LoadingSpinner from '../../../../../../../../utils/LoadingSpinner';

import Photocamera from '../../../../../../../../utils/Photocamera';
import StreamCamera from '../../../../../../../../utils/StreamCamera';

function NewRecord({
	siteData,
	lineData,
	lastCompanySelected,
	setLastCompanySelected,
	clear,
}) {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const [currLine, setCurrLine] = useState(lineData || null);

	const [formState, inputHandler, setFormData, setInputs] = useForm({
		observation: {
			value: '',
			isValid: false,
			el: 'textarea',
			type: 'textarea',
			label: 'Descrizione attività',
			validator: [VALIDATOR_NO()],
			initIsValid: false,
			initValue: '',
			rows: 6,
			style: { height: 'auto' },
		},
		prescription: {
			value: '',
			isValid: true,
			el: 'textarea',
			type: 'textarea',
			label: 'Prescrizione',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: '',
			rows: 6,
			style: { height: 'auto' },
		},
		dueDate: {
			value: '',
			isValid: false,
			el: 'date',
			type: 'date',
			label: 'Termine',
			validator: [VALIDATOR_REQUIRE()],
			initValue: '',
			initIsValid: true,
		},
	});

	const [inputsVisuals, setInputsVisuals] = useState(setInputs(formState));

	/**> Picture -------------------------------------------------------- */
	const [imageUrl, setImageUrl] = useState(null);
	const [imagePreview, setImagePreview] = useState('');
	const [fileData, setFileData] = useState(null);

	const displayPicture = () => {
		if (currLine?.image) {
			return (
				<div className={classes.imageWrapper}>
					<img src={mainVariables.srv + '/' + currLine.image} />
				</div>
			);
		} else {
			return (
				<div className={classes.addImage}>
					<div className={classes.imgButtons}>
						<Photocamera
							saveUrl={'report/lines/picture_ADD'}
							folder={reportState.reportHeader.imgFolder}
							setImageUrl={setImageUrl}
						/>
					</div>
					{imageUrl && (
						<img
							id='imagePreview'
							alt="Preview dell'immagine"
							src={mainVariables.srv + 'images/' + imageUrl}
							style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
						/>
					)}
					{imagePreview && (
						<div className={classes.imgDescription}>
							<p>{fileData?.name}</p>
						</div>
					)}
				</div>
			);
		}
	};

	/**< Picture -------------------------------------------------------- */

	const abortInsert = async () => {
		if (imageUrl) {
			console.log('Elimino immagine non utilizzata');
			const imgPath = imageUrl.split('?');

			if (imgPath.length > 1) {
				imgPath.pop();
			}

			await sendRequest(
				'report/lines/picture_DELETE',
				'POST',
				{ imageName: imgPath.join('') },
				{ 'Content-Type': 'application/json' }
			);
		}
		closeForm();
	};

	const saveData = async () => {
		const l_observation = formState.inputs.observation.value || '';
		const l_prescription = formState.inputs.prescription.value || '';
		const l_dueDate = formState.inputs.dueDate.value || '';

		const r_line = {
			refSite: reportState.site._id,
			refReportHeader: reportState.reportHeader._id,

			refReportType: reportState.reportHeader.reportModel,
			refCompany: lastCompanySelected.refContatto,

			creationDate: new Date(),
			date: new Date(reportState.reportHeader.creationDate),

			observation: l_observation,
			prescription: l_prescription,

			// refTarget: [{ type: Schema.Types.ObjectId, ref: 'Contact' }],
			// refReferent: { type: Schema.Types.ObjectId, ref: 'Contact' },

			// severity: { type: Number },
		};

		if (l_dueDate) {
			r_line.dueDate = l_dueDate;
		}

		if (imageUrl) {
			const imageName = imageUrl.split('?');
			imageName.pop();
			r_line.imageUrl = imageName.join('');
			r_line.imageName = imageName.join('');
		}

		if (l_prescription !== '' && l_dueDate === '') {
			window.alert(
				'Necessario indicare un termine per la risoluzione della prescrizione'
			);
		}

		const data = await sendRequest(
			`report/line/add`,
			'POST',
			{ lineData: r_line },
			{ 'Content-Type': 'application/json' }
		);

		rActions?.updateReportLines(data);
		closeForm();
	};

	const closeForm = async () => {
		clear();
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			<div className={classes.wrapper}>
				<div className={classes.header}></div>
				<div className={classes.body}>
					<i className={classes.noPrint}>
						Per ogni azienda indicare nr. Addetti presenti e descrizione
						attività in corso
					</i>
					<Find
						// url={`contacts/companyActivePersons/${contact?.refContatto?._id}`}
						manualList={siteData.contacts}
						setRes={setLastCompanySelected}
						label={'Azienda'}
						inputId={`selAzienda`}
						initialValue={lastCompanySelected?.refContatto?.fullname}
						initValue={lastCompanySelected?.refContatto?.fullname}
						driver={'refContatto'}
						driver2={'fullname'}
						resName={null}
						isArray={true}
						contStyle={{ width: '100%' }}
						style={{ fontWeight: 200 }}
						// filter={siteReferents}
						// filterParam={'_id'}
					/>
					{reportState && lastCompanySelected && displayPicture()}
					{reportState && lastCompanySelected && inputsVisuals}
				</div>
				<div className={classes.buttons}>
					<IconButton
						text={'cancel'}
						style={{ color: 'var(--STOPED)', padding: 0 }}
						action={abortInsert}
					/>

					<span
						style={
							formState.isValid || imageUrl
								? { color: 'var(--ONGOING)' }
								: { color: 'gray' }
						}
					>
						<IconButton
							text={'check_circle'}
							style={{ padding: 0 }}
							action={saveData}
							enabled={formState.isValid}
						/>
					</span>
				</div>
			</div>
		</React.Fragment>
	);
}

export default NewRecord;
