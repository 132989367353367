import React, { useEffect, useState } from 'react';

import classes from './EditForm.module.css';

import { useForm } from '../../../../hooks/form-hook';
import { VALIDATOR_NO, VALIDATOR_REQUIRE } from '../../../../utils/validators';
import Input from '../../../../utils/Inputs/Input';
import Button from '../../../../utils/Button/Button';

function EditForm({ data, action, clear }) {
	const [checkSelected, setCheckSelected] = useState(
		data?.isPerson ? 'isPerson' : data?.isCompany ? 'isCompany' : null
	);

	const checkedHandler = id => {
		let _val = Boolean(document.getElementById(id).checked);
		inputHandler(id, _val, true);

		if (id !== 'isActive') {
			setCheckSelected(prev => {
				let _pVal = document.getElementById(prev);
				if (_pVal) {
					_pVal.checked = false;
					inputHandler(prev, false, true);
				}
				return id;
			});
		}
	};

	const [formState, inputHandler, setFormData] = useForm({
		name: {
			value: data?.name || '',
			isValid: Boolean(data?.name),
			el: 'input',
			type: 'text',
			label: 'Nome',
			validator: [VALIDATOR_REQUIRE()],
			initValue: data?.name || '',
			initIsValid: Boolean(data?.name),
		},
		forPerson: {
			value: data?.forPerson || false,
			isValid: true,
			el: 'checkbox',
			type: 'checkbox',
			label: 'Per Persone',
			validator: [VALIDATOR_NO()],
			errorText: 'none',
			initValue: data?.forPerson || '',
			initIsValid: true,
			execute: () => checkedHandler('forPerson'),
		},
		forCompany: {
			value: data?.forCompany || false,
			isValid: true,
			el: 'checkbox',
			type: 'checkbox',
			label: 'Per Aziende',
			validator: [VALIDATOR_NO()],
			errorText: 'none',
			initValue: data?.forCompany || '',
			initIsValid: true,
			execute: () => checkedHandler('forCompany'),
		},
		description: {
			value: data?.description || '',
			isValid: true,
			el: 'textarea',
			type: 'textarea',
			label: 'Descrizione',
			validator: [VALIDATOR_NO()],
			initValue: data?.description || '',
			initIsValid: true,
			width: '100%',
			rows: 5,
			style: { heigth: 'auto' },
		},
		isActive: {
			value: data?.isActive || true,
			isValid: true,
			el: 'checkbox',
			type: 'checkbox',
			label: 'Attivo',
			validator: [VALIDATOR_NO()],
			errorText: 'none',
			initValue: data?.isActive || true,
			initIsValid: true,
			execute: () => checkedHandler('isActive'),
		},
	});

	const setInputs = () => {
		let inputs = formState.inputs;
		let keys = Object.keys(formState.inputs);

		const inputsVisual = keys.map(k => {
			let i = inputs[k];
			return (
				<Input
					key={k}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
					name={i.name}
					list={i.list}
					width={i.width || 'auto'}
					style={{ ...i.style, padding: '0.5rem' }}
					setSelect={i.setSelect}
					execute={i.execute}
					defaultChecked={Boolean(i.defaultChecked)}
					hide={i.hide}
					contStyle={i.contStyle}
					rows={i.rows}
				/>
			);
		});
		return inputsVisual;
	};

	const postData = () => {
		const n_data = {};

		const keys = Object.keys(formState.inputs);

		keys.map(k => {
			if (formState.inputs[k]?.value !== undefined) {
				n_data[k] = formState.inputs[k]?.value;
			}
		});
		if (data._id) {
			n_data._id = data._id;
		}
		action(n_data);
		clear();
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={clear}></div>
			<div className={classes.wrapper}>
				{setInputs()}

				<div className={classes.buttons}>
					<Button
						clname='confirm big'
						action={postData}
						disabled={!formState.isValid || checkSelected === null}
					>
						Salva
					</Button>
					<Button clname='danger big' action={clear}>
						Annulla
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default EditForm;
