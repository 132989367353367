import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, NavLink } from 'react-router-dom';

import classes from './Report.module.css';

import ModelSelection from './Models/ModelSelection';

import IconButton from '../../../utils/IconButton';

import { useHttpClient } from '../../../hooks/http-hooks';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import ErrorModal from '../../../utils/ErrorModal';

import { UserCxt } from '../../../context/UserCxt';

import { ReportCxt } from '../../../context/reportCxt';
import { useReportHooks } from '../../../hooks/report-hook';
/** */
import ExctSafety from './Models/Inspection/ExctSafety/ExctSafety';
/** */

import { reportTypes, reportModels } from './ReportsData';

function Report() {
	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const p = useParams();
	const routerLocation = useLocation();
	const reportCxt = useContext(ReportCxt);
	const userCxt = useContext(UserCxt);

	const [site, setSite] = useState(routerLocation?.state?.site || null);
	const [reportHeader, setReportHeader] = useState(null);
	const [reportLines, setReportLines] = useState([]);

	const rHooks = useReportHooks({
		site,
		reportHeader,
		reportLines,
	});

	useEffect(() => {
		console.log({ site });
		if (site) {
			rHooks.addState(site);
		}
	}, [site]);

	// console.log(rHooks); //todo: in report state mancano i riferimenti al cantiere, trovare un modo per inserirli

	/**--------------------------------------
	 * Gestione Report
	 * -------------------------------------*/

	const createNewReport = async () => {
		const userID = userCxt.user[0]._id;

		const report = await sendRequest(
			`report/create`,
			'POST',
			{
				siteID: site._id,
				reportModel: reportModel,
				userID: userID,
				imgFolderName: site.imgFolderName,
			},
			{ 'Content-Type': 'application/json' }
		);

		if (report) {
			await getReportLinesData(report._id);
			// const lines = await sendRequest(`report/lines/${report._id}`);
			// rHooks.updateReportLines(lines);
		}

		setReportHeader(report);

		rHooks.updateReportHeader(report);
	};

	const getReportHeaderData = async rID => {
		console.log('here');
		const report = await sendRequest(`report/open/${rID}`);

		await getReportLinesData(report._id);

		setReportHeader(report);
		rHooks.updateReportHeader(report);

		setReportModel(report.reportModel);
	};

	const getReportLinesData = async rID => {
		const lines = await sendRequest(`report/lines/${rID}`);
		rHooks.updateReportLines(lines);
	};

	/**------------------------------------ */

	const [reportModel, setReportModel] = useState(null);

	useEffect(() => {
		if (p.report === 'new' && reportModel) {
			createNewReport();
		}
	}, [reportModel]);

	useEffect(() => {
		if (p.report !== 'new') {
			getReportHeaderData(p.report);
		}
	}, []);

	const evalReportModel = () => {
		switch (reportModel) {
			case 'ExctSafety':
				return <ExctSafety />;

			default:
				return <div>Report non definito</div>;
		}
	};

	const test = async () => {
		return;
		const test = await sendRequest('report/test');

		console.log({ test });
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			<ReportCxt.Provider value={rHooks}>
				<div className={classes.wrapper}>
					<div className={classes.header}>
						<NavLink to={`../Cantieri/${p.site}`}>
							<IconButton text={'arrow_back'} />
						</NavLink>
						{p.report === 'new' && (
							<div className={classes.headerDescription} onClick={test}>
								Verbale nuovo
							</div>
						)}
					</div>
					<div className={classes.body}>
						{p.report === 'new' && !reportModel && (
							<ModelSelection selectModel={setReportModel} />
						)}
						{reportModel && evalReportModel()}
					</div>
				</div>
			</ReportCxt.Provider>
		</React.Fragment>
	);
}

export default Report;
