import React from 'react';

import classes from './ContactCard.module.css';

function ContactCard({ contact, select }) {
	const doAction = () => {
		select(contact);
	};

	return (
		<div className={classes.wrapper} onClick={doAction}>
			<p>{contact?.fullname}</p>
			<p>{contact?.refCompany?.name}</p>
			<p>{contact?.refRole?.name}</p>
		</div>
	);
}

export default ContactCard;
