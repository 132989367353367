import React, { useState, useEffect } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import ReactDom from 'react-dom';

import { useHttpClient } from '../../../hooks/http-hooks';
import ErrorModal from '../../../utils/ErrorModal';
import LoadingSpinner from '../../../utils/LoadingSpinner';

import Button from '../../../utils/Button/Button';
import IconButton from '../../../utils/IconButton';

import Committente from './Committente/Committente';
import DatiCantiere from './DatiCantiere/DatiCantiere';
/** */ import EditSite from './DatiCantiere/Comps/EditSite';
import Registrazioni from './Registrazioni/Registrazioni';
import ContattiCantiere from './ContattiCantiere/ContattiCantiere';

import classes from './SitePage.module.css';

function SitePage() {
	const param = useParams();

	const getSiteData = async () => {
		if (param.site === 'new') {
			return;
		}
		const sData = await sendRequest(`site/siteData/${param.site}`);
		setSite(sData);
	};

	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const [site, setSite] = useState(null);
	/**
	 * -------------------------------------------
	 * Imposta pagina cantiere
	 * -------------------------------------------
	 */

	const fasi = {
		COMMITTENTE: 'Committente',
		REFERENTE: 'Referente della committenza',
		DATI: 'Dati',
		CONTATTI: 'Contatti',
	};

	const [committente, setCommittente] = useState(null);
	const [referente, setReferente] = useState(null);
	const [siteData, setSiteData] = useState(null);
	const [siteContacts, setSiteContacts] = useState(null);

	const [initialized, setInitialized] = useState(false);
	const [contactsUpdated, setContactsUpdated] = useState(false);

	const [btnUpdateDisabled, setBtnUpdateDisabled] = useState(true);

	const addElement = value => {
		console.log(value);

		switch (value) {
			case fasi.COMMITTENTE:
				break;
			case fasi.REFERENTE:
				break;
			case fasi.DATI:
				handleShowSiteDataForm();
				break;
			case fasi.CONTATTI:
				break;

			default:
				console.log('Niente');
				break;
		}
	};

	const evalUpdatedData = () => {
		const keys = Object.keys(siteData);

		const uSite = {};
		keys.map(k => {
			if (siteData[k] !== site?.[k]) {
				uSite[k] = siteData[k];
			}
		});

		if (
			site?.committente?.refContatto?._id !== committente.refContatto._id ||
			site?.committente?.sendAlwaysMail !== committente.sendAlwaysMail
		) {
			console.log('Committente');
			uSite.committente = { ...committente };
			uSite.committente.refContatto = committente.refContatto._id;
		}

		if (
			site?.contactPerson?.refContatto?._id !== referente.refContatto._id ||
			site?.contactPerson?.sendAlwaysMail !== referente.sendAlwaysMail
		) {
			console.log('Referente');
			uSite.contactPerson = { ...referente };
			uSite.contactPerson.refContatto = referente.refContatto._id;
		}

		if (contactsUpdated) {
			console.log('Contatti');
			// uSite.contacts = [...siteContacts];
			console.log(siteContacts);

			uSite.contacts = siteContacts.map(c => {
				console.log({ c });
				if (c?.siteReferents?.length > 0) {
					const uC = c.siteReferents.map(r => {
						console.log({ r });
						if (!r.refSiteRole) {
							console.log('ok');
							r.refSiteRole = r.refContatto.refRole;
						}
					});
					// c.siteReferents = uC;
				}
				return c;
			});
		}

		// const imgUrl = document.getElementById('siteCover')?.src;
		if (site?.coverImg) {
			uSite.coverImg = site?.coverImg;
		}

		return uSite;
	};

	const goTo = useNavigate();
	const saveSiteData = async () => {
		const uSite = evalUpdatedData();

		uSite.creationDate = new Date();

		const nSite = await sendRequest(
			'site/newSite',
			'POST',
			{ site: uSite },
			{ 'Content-Type': 'application/json' }
		);

		setSite(nSite);
		setBtnUpdateDisabled(true);

		if (param.site === 'new') {
			goTo('/Cantieri/' + nSite._id);
		}
	};

	const updateSiteData = async () => {
		console.log(siteData);

		const uSite = evalUpdatedData();

		console.log({ uSite });

		const nSite = await sendRequest(
			'site/updateSite',
			'POST',
			{ site: uSite, id: site._id },
			{ 'Content-Type': 'application/json' }
		);

		// setSite(nSite);
		setInitialized(false);
		setBtnUpdateDisabled(true);
		getSiteData();
	};

	//todo: Funzione da rimuovere
	const buttonoAdd = val => {
		if (site) {
			return <IconButton text={'edit'} action={() => addElement(val)} />;
		} else {
			return (
				<IconButton
					text={'add_circle'}
					style={{ fontSize: '2rem' }}
					action={() => addElement(val)}
				/>
			);
		}
	};

	/**<<< Valutazione stato bottone----------- */

	/**
	 * -------------------------------------------
	 * Modifica dati cantiere
	 * -------------------------------------------
	 */

	const [showSiteDataForm, setShowSiteDataForm] = useState(false);

	const handleShowSiteDataForm = () => {
		setShowSiteDataForm(!showSiteDataForm);
	};

	const openSiteDataForm = () => {
		const form = (
			<EditSite
				clear={handleShowSiteDataForm}
				site={siteData}
				setSite={setSiteData}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	/**<<< Modifica dati cantiere --------------------- */

	const handleInizializedValues = () => {
		if (committente !== null && referente !== null && siteData !== null) {
			setInitialized(true);
		}
	};

	useEffect(() => {
		if (!committente) {
			setReferente(null);
		} else {
			if (initialized) {
				setBtnUpdateDisabled(false);
			} else {
				handleInizializedValues();
			}
		}
	}, [committente]);

	useEffect(() => {
		if (referente) {
			if (initialized) {
				setBtnUpdateDisabled(false);
			} else {
				handleInizializedValues();
			}
		}
	}, [referente]);

	useEffect(() => {
		if (siteData) {
			if (initialized) {
				setBtnUpdateDisabled(false);
			} else {
				handleInizializedValues();
			}
		}
	}, [siteData]);

	useEffect(() => {
		if (siteContacts) {
			if (initialized) {
				setBtnUpdateDisabled(false);
				setContactsUpdated(true);
				console.log({ siteContacts });
			}
		} else {
			console.log('no-site-contacts');
			handleInizializedValues();
		}
	}, [siteContacts]);

	useEffect(() => {
		if (site) {
			setCommittente(site?.committente);
			setReferente(site?.contactPerson);
			setSiteContacts(site?.contacts);
			setSiteData(site);
		}
	}, [site]);

	useEffect(() => {
		getSiteData();
	}, []);

	const deleteSite = async () => {
		if (
			window.confirm(
				'Sei sicuro di volere eliminare il cantiere e tutti gli elementi collegati?'
			)
		) {
			console.log('Elimino');
			await sendRequest('site/delete/' + site._id);
			window.alert(
				'Il cantiere e tutti gli elementi correlati sono stati eliminati'
			);
			goTo('/Cantieri');
		}
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			{showSiteDataForm && openSiteDataForm()}
			<div className={classes.wrapper}>
				<div className={classes.header}>
					<div className={classes.header}>
						<Button clname='reverse'>
							<NavLink to={'../Cantieri'}>{`<< `} Tutti i Cantieri</NavLink>
						</Button>
						<h1>{siteData?.name || 'Nuovo Cantiere'}</h1>
					</div>
					<NavLink to={'report/new'} state={{ site: site }}>
						<IconButton
							text={'post_add'}
							style={{
								fontSize: '2rem',
								color: 'var(--activeLink)',
								backgroundColor: 'var(--contrast)',
								borderRadious: '50px',
								border: '1px solid var(--text)',
								padding: '0.6rem',
							}}
						/>
					</NavLink>
				</div>
				<div className={classes.body}>
					<DatiCantiere
						data={siteData || site}
						action={handleShowSiteDataForm}
					/>

					{site && (
						<Registrazioni
							site={site}
							openReports={site?.openReports}
							openActivities={site?.openActivities}
						/>
					)}

					<Committente
						target={committente}
						subject={fasi.COMMITTENTE}
						setResult={setCommittente}
						newParam={param.site}
						sourceUrl={'contacts/activeCompanysList'}
					/>

					{committente && (
						<Committente
							target={referente}
							subject={fasi.REFERENTE}
							parent={committente}
							setResult={setReferente}
							newParam={param.site}
							sourceUrl={`contacts/companyActivePersons/${committente?.refContatto?._id}`}
						/>
					)}

					{siteContacts && (
						<ContattiCantiere
							contacts={siteContacts}
							updateContacts={setSiteContacts}
							add={buttonoAdd(fasi.CONTATTI)}
						/>
					)}
				</div>

				{site ? (
					<div
						className={
							btnUpdateDisabled ? classes.saveDisabled : classes.saveEnabled
						}
					>
						<IconButton text={'save'} action={updateSiteData} />
					</div>
				) : (
					<Button clname='confirm big' action={saveSiteData}>
						Salva
					</Button>
				)}
				{/* <IconButton text='delete' action={deleteSite} /> */}
			</div>
		</React.Fragment>
	);
}

export default SitePage;
