import React, { useState, useEffect } from 'react';

import classes from './EditWeekStructure.module.css';

import { useForm } from '../../../../hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../../../utils/validators';
import Input from '../../../../utils/Inputs/Input';
import Button from '../../../../utils/Button/Button';
import IconButton from '../../../../utils/IconButton';

function EditWeekStructure({
	data,
	setNewData,
	entrance,
	setWeeklyEntrance,
	clear,
}) {
	const days = {
		0: 'Domenica',
		1: 'Lunedì',
		2: 'Martedì',
		3: 'Mercoledì',
		4: 'Giovedì',
		5: 'Venerdì',
		6: 'Sabato',
	};
	console.log(entrance);
	if (!entrance) {
		entrance = {
			0: [],
			1: ['8:00', '14:00'],
			2: ['8:00', '14:00'],
			3: ['8:00', '14:00'],
			4: ['8:00', '14:00'],
			5: ['8:00', '14:00'],
			6: [],
		};
	}

	const [tEntrance, setTEntrance] = useState(entrance);

	const getFormData = () => {
		const dataForm = {};

		Object.keys(data).forEach(d => {
			dataForm[days[d]] = {
				value: Number(data[d]),
				isValid: true,
				el: 'input',
				type: 'number',
				label: days[d],
				validator: [VALIDATOR_REQUIRE()],
				initValue: Number(data[d]),
				initIsValid: true,
			};
		});

		// console.log(dataForm);
		return dataForm;
	};

	const [formState, inputHandler, setFormData] = useForm(getFormData());

	const setInputs = () => {
		let inputs = formState.inputs;
		let keys = Object.keys(formState.inputs);

		const inputsVisual = keys.map(k => {
			let i = inputs[k];
			return (
				<Input
					key={k}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
					width={'80px'}
					style={{ width: '80px', textAlign: 'center' }}
				/>
			);
		});
		return inputsVisual;
	};

	const deleteEntrace = (day, value) => {
		const list = tEntrance[day];
		const newList = list.filter(l => l !== value);
		setTEntrance({ ...tEntrance, [day]: newList });
	};

	const addNewEntrance = day => {
		const value = document.getElementById(`inputEtrataGiorno${day}`).value;
		let isOk = false;
		if (value) {
			const test = value.split(':');
			isOk = test.length === 2;
			isOk = !isNaN(test[0]);
			isOk = !isNaN(test[1]);
		}

		if (!isOk) {
			window.alert('Il valore inserito non è corretto:');
			return;
		}

		const list = tEntrance[day];
		list.push(value);

		document.getElementById(`inputEtrataGiorno${day}`).value = '';
		setTEntrance({ ...tEntrance, [day]: list });
	};

	const setEntrance = () => {
		// let inputs = formState.inputs;
		let keys = Object.keys(days);
		let vis = keys.map(d => {
			const e = tEntrance[d];
			// console.log({ e });

			return (
				<div className={classes.dayEntrance}>
					Entrata
					<div>
						{e.map(h => (
							<div className={classes.entrance}>
								{h}
								<div className={classes.entranceDelete}>
									<IconButton
										text={'cancel'}
										action={() => deleteEntrace(d, h)}
									/>
								</div>
							</div>
						))}
					</div>
					<div className={classes.entranceAdd}>
						<input
							id={`inputEtrataGiorno${d}`}
							className={classes.entranceAdd__input}
							type={'text'}
						/>
						<div className={classes.entranceAdd__button}>
							<IconButton
								text={'add_circle'}
								action={() => addNewEntrance(d)}
							/>
						</div>
					</div>
				</div>
			);
		});

		return vis;
	};

	const saveData = () => {
		const newData = {};

		Object.keys(data).forEach(d => {
			newData[d] = Number(formState.inputs[days[d]].value);
		});

		// console.log({ data });
		// console.log({ newData });
		setWeeklyEntrance(tEntrance);
		setNewData(newData);
		clear();
	};

	return (
		<React.Fragment>
			{/* {isLoading && <LoadingSpinner asOverlay />}
			{error && <ErrorModal error={error} onClear={clearError} />} */}
			<div className={classes.background} onClick={clear} />
			<div className={classes.wrapper}>
				<section>
					<div className={classes.form}>{setInputs()}</div>
					<div className={`${classes.form} ${classes.form__entrance}`}>
						<div className={classes.displayEntrance}>{setEntrance()}</div>
						{/* <div
							className={`${classes.insertEntrance} ${
								!selectedDay && classes.hidden
							}`}
						>
							{console.log(selectedDay)}
							Inserire valore e selezoinare i giorni a cui applicare
							<br />
							{days[selectedDay]}
						</div> */}
					</div>
				</section>
				<div className={classes.form__buttons}>
					<Button
						clname='danger'
						onClick={clear}
						style={{ fontSize: 20 + 'px' }}
					>
						Annulla
					</Button>
					<Button
						clname='confirm'
						style={{ fontSize: 20 + 'px' }}
						disabled={!formState.isValid}
						onClick={saveData}
					>
						Inserisci
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default EditWeekStructure;
