import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import classes from './Contatti.module.css';

import { useHttpClient } from '../../hooks/http-hooks';
import ErrorModal from '../../utils/ErrorModal';
import LoadingSpinner from '../../utils/LoadingSpinner';

// import { useForm } from '../../hooks/form-hook';
// import { VALIDATOR_NO } from '../../utils/validators';
// import Input from '../../utils/Inputs/Input';
import Button from '../../utils/Button/Button';

import ContactForm from './Comps/ContactForm';
import ContactCard from './Comps/ContactCard';

function Contatti() {
	const { clearError, error, isLoading, sendRequest } = useHttpClient();
	const [isLocaleLoading, setIsLocaleLoading] = useState(false);

	/**
	 * ----------------------------------------------
	 * > Caricamento iniziale
	 * ----------------------------------------------
	 */

	const [contactsList, setContactsList] = useState([]);
	const [contactsVisual, setContactsVisual] = useState(null);
	const [selectActiveCts, setSelectActiveCts] = useState(false);

	const getContactsList = async () => {
		const data = await sendRequest('contacts/activeContactsList');
		console.log('carico dati contatti');
		setContactsList(data);
	};

	const getInactiveContactsList = async () => {
		const url = selectActiveCts
			? 'contacts/activeContactsList'
			: 'contacts/inactiveContactsList';
		const data = await sendRequest(url);
		console.log('carico dati contatti');
		setContactsList(data);
		setSelectActiveCts(!selectActiveCts);
	};

	const evalContactsVisual = () => {
		let lastType = '';
		const _v = [];

		contactsList.map(c => {
			if (lastType !== c.isPerson) {
				lastType = c.isPerson;
				if (c.isPerson) {
					_v.push(<div className={classes.subTitle}>PERSONE</div>);
				}
				if (c.isCompany) {
					_v.push(<div className={classes.subTitle}>AZIENDE</div>);
				}
			}
			_v.push(
				<ContactCard key={c._id} contact={c} select={setSelectedContact} />
			);
		});

		setContactsVisual(_v);
	};

	useEffect(() => {
		evalContactsVisual();
	}, [contactsList]);

	useEffect(() => {
		getContactsList();
	}, []);

	/** < Caricamento iniziale */

	/**
	 * ----------------------------------------------
	 * > Nuovo contatto
	 * ----------------------------------------------
	 */

	const [selectedContact, setSelectedContact] = useState(null);
	const [showContactForm, setShowContactForm] = useState(false);

	const handleShowContactForm = () => {
		setShowContactForm(!showContactForm);
	};

	const sortContacts = (nC, isNew = false) => {
		if (isNew) {
			setContactsList(prev => {
				const newArray = [...prev];

				newArray.push(nC);
				newArray.sort((a, b) => {
					let textA = `${a.isPerson ? 'P' : 'A'}_${a.name}`;
					let textB = `${b.isPerson ? 'P' : 'A'}_${b.name}`;
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				});

				return newArray;
			});
		} else {
			const newContactList = [...contactsList];
			const ix = contactsList.findIndex(el => {
				return el._id === nC._id;
			});

			newContactList[ix] = { ...nC };

			setContactsList(newContactList);
		}
	};

	const openContactForm = () => {
		const form = (
			<ContactForm
				clear={handleShowContactForm}
				action={sortContacts}
				contact={selectedContact}
				setContact={setSelectedContact}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	useEffect(() => {
		if (selectedContact) {
			handleShowContactForm();
		}
	}, [selectedContact]);

	/**
	 * ----------------------------------------------
	 */

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{(isLoading || isLocaleLoading) && <LoadingSpinner asOverlay />}
			{showContactForm && openContactForm()}
			<div className={classes.wrapper}>
				<h1> Contatti</h1>
				<div className={classes.buttons}>
					<Button clname={'confirm big'} action={handleShowContactForm}>
						Nuovo contatto
					</Button>
					<div className={classes.filters}>
						<Button
							clname={selectActiveCts && 'reverse'}
							action={() => getInactiveContactsList()}
						>
							{selectActiveCts ? 'Vedi ATTIVI' : 'Vedi \nNON ATTIVI'}
						</Button>
						{/* <Button
							clname={selectActiveCts && 'reverse'}
							action={() => getInactiveContactsList()}
						>
							{selectActiveCts ? 'Vedi ATTIVI' : 'Vedi \nNON ATTIVI'}
						</Button> */}
					</div>
				</div>
				<div className={classes.cardsWrapper}>{contactsVisual}</div>
			</div>
		</React.Fragment>
	);
}

export default Contatti;
