import React from 'react';

import classes from './Report_SiteContactCard.module.css';

function Report_SiteContactCard({ contact, role }) {
	return (
		<div className={classes.wrapper}>
			{role && <p className={classes.title}>{role}</p>}
			<div className={classes.grid3}>
				<div className={classes.col}>
					<p className={classes.name}>{contact?.fullname}</p>
					<p>{contact?.address}</p>
				</div>
				<div className={classes.col}>
					<p>Tel: {contact?.phoneNumber}</p>
					<p>Mail: {contact?.email}</p>
				</div>
			</div>
		</div>
	);
}

export default Report_SiteContactCard;
