import React, { useEffect, useState, useRef } from 'react';

import classes from './ContactForm.module.css';

import { useForm } from '../../../hooks/form-hook';
import { VALIDATOR_NO, VALIDATOR_REQUIRE } from '../../../utils/validators';
import Input from '../../../utils/Inputs/Input';
import Button from '../../../utils/Button/Button';

import { useHttpClient } from '../../../hooks/http-hooks';
import ErrorModal from '../../../utils/ErrorModal';
import LoadingSpinner from '../../../utils/LoadingSpinner';

import Find from '../../../utils/Inputs/Find';

function ContactForm({
	contact = null,
	action,
	clear,
	setContact,
	nType = null,
	company = null,
}) {
	const { clearError, error, isLoading, sendRequest } = useHttpClient();
	const prevType = useRef(null);
	/**
	 * ----------------------------------------------
	 * > Inserimento campi input
	 * ----------------------------------------------
	 */

	const checkedHandler = id => {
		let _val = Boolean(document.getElementById(id).checked);
		inputHandler(id, _val, true);
		formState.inputs[id].value = _val;
	};

	const [selectedOption, setSelectedOption] = useState(
		(contact?.isPerson && 'Persona') ||
			(contact?.isCompany && 'Azienda') ||
			nType
	);
	const [selectedRefCompany, setSelectedRefCompany] = useState(
		contact?.refCompany?.name || company || ''
	);
	const [selectedRefRole, setSelectedRefRole] = useState(
		contact?.refRole || ''
	);

	let validity = false;
	if (contact !== undefined) {
		validity = true;
	}

	const evalGridStyle = () => {
		const w = window.matchMedia('(min-width: 768px)');
		if (w.matches) {
			return { gridColumn: '1 / 5' };
		} else {
			return { gridColumn: '1' };
		}
	};

	useEffect(() => {
		if (prevType.current !== null && selectedOption) {
			prevType.current = selectedOption;
			setSelectedRefRole('');
			setSelectedRefCompany('');
		}
	}, [selectedOption]);

	const [formState, inputHandler, setFormData] = useForm({
		type: {
			value: selectedOption,
			isValid: validity,
			el: 'radio',
			type: 'radio',
			label: 'Tipo contatto',
			validator: [VALIDATOR_REQUIRE()],
			list: [
				{ id: 0, value: 'contact_type_person', name: 'Persona' },
				{ id: 1, value: 'contact_type_company', name: 'Azienda' },
			],
			initIsValid: validity,
			initValue: selectedOption,
			setSelect: setSelectedOption,
			contStyle: evalGridStyle(),
			hide: Boolean(nType),
		},
		name: {
			value: contact?.name || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Nome',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: validity,
			initValue: contact?.name || '',
		},
		surname: {
			value: contact?.surname || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Cognome',
			validator: [VALIDATOR_REQUIRE()],
			hide: !(selectedOption === 'Persona'),
			initIsValid: validity,
			initValue: contact?.surname || '',
		},
		jobTitle: {
			value: contact?.jobTitle || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Titolo',
			validator: [VALIDATOR_NO()],
			hide: !(selectedOption === 'Persona'),
			initIsValid: validity,
			initValue: contact?.jobTitle || '',
		},
		address: {
			value: contact?.address || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Indirizzo',
			validator: [VALIDATOR_NO()],
			initIsValid: validity,
			initValue: contact?.address || '',
		},
		city: {
			value: contact?.city || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Città',
			validator: [VALIDATOR_NO()],
			initIsValid: validity,
			initValue: contact?.city || '',
		},
		county: {
			value: contact?.county || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Regione',
			validator: [VALIDATOR_NO()],
			initIsValid: validity,
			initValue: contact?.county || '',
		},
		country: {
			value: contact?.country || '',
			isValid: validity,
			el: 'input',
			type: 'text',
			label: 'Nazione',
			validator: [VALIDATOR_NO()],
			initIsValid: validity,
			initValue: contact?.country || '',
		},

		phoneNumber: {
			value: contact?.phoneNumber || '',
			isValid: true,
			el: 'input',
			type: 'text',
			label: 'Numero di telefono',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: contact?.phoneNumber || '',
		},
		email: {
			value: contact?.email || '',
			isValid: true,
			el: 'input',
			type: 'text',
			label: 'Email',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: contact?.email || '',
		},
		isActive: {
			value: contact?.isActive || true,
			isValid: true,
			el: 'checkbox',
			type: 'checkbox',
			label: 'Attivo',
			validator: [VALIDATOR_NO()],
			execute: () => checkedHandler('isActive'),
			initIsValid: true,
			initValue: contact?.isActive || true,
		},
		description: {
			value: contact?.description || true,
			isValid: true,
			el: 'textarea',
			type: 'textarea',
			label: 'Descrizione',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: contact?.description || '',
			contStyle: evalGridStyle(),
		},
	});

	const setInputs = () => {
		let inputs = formState.inputs;
		let keys = Object.keys(formState.inputs);

		const inputsVisual = keys.map(k => {
			let i = inputs[k];
			switch (k) {
				case 'type':
					i.initValue = selectedOption;
					i.contStyle = evalGridStyle();
					break;

				case 'name':
					if (selectedOption === 'Persona') {
						i.label = 'Nome';
					}
					if (selectedOption === 'Azienda') {
						i.label = 'Ragione sociale';
					}
					break;
				case 'role':
					if (selectedOption === 'Persona') {
						i.label = 'Ruolo';
					}
					if (selectedOption === 'Azienda') {
						i.label = 'Settore';
					}
					break;

				case 'surname':
				case 'jobTitle':
				case 'refCompany':
					i.hide = !(selectedOption === 'Persona');
					break;

				default:
					break;
			}

			return (
				<Input
					key={k}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
					name={i.name}
					list={i.list}
					width={'auto'}
					style={{ ...i.style, padding: '0.5rem' }}
					setSelect={i.setSelect}
					execute={i.execute}
					defaultChecked={Boolean(i.defaultChecked)}
					hide={i.hide}
					contStyle={i.contStyle}
				/>
			);
		});
		return inputsVisual;
	};

	/**
	 * < Inserimento input ----------------------------------------------
	 */

	/**
	 * ----------------------------------------------
	 * > Gestione post
	 * ----------------------------------------------
	 */

	const evalButtonDisabled = () => {
		let disabled = !formState.isValid;

		disabled = selectedRefRole.name === undefined;
		if (selectedOption === 'Persona') {
			disabled = selectedRefCompany.name === undefined;
		}

		return disabled;
	};

	const post = async e => {
		e.preventDefault();

		const nContact = {};

		const keys = Object.keys(formState.inputs);

		keys.map(k => {
			switch (k) {
				case 'type':
					break;
				case 'isActive':
					nContact[k] = Boolean(document.getElementById(k).checked);
					break;

				default:
					if (formState.inputs[k].value !== '') {
						nContact[k] = formState.inputs[k].value;
					}
					break;
			}
		});

		if (selectedRefRole?.name) {
			nContact.refRole = selectedRefRole._id;
		}
		if (selectedRefCompany?.name) {
			nContact.refCompany = selectedRefCompany._id;
		}

		if (selectedOption === 'Azienda') {
			nContact.isCompany = true;
			nContact.isPerson = false;
		}
		if (selectedOption === 'Persona') {
			nContact.isCompany = false;
			nContact.isPerson = true;
		}

		if (contact) {
			nContact._id = contact._id;
		}

		const result = await postNewContact(nContact, contact === null);
		action(result.c, result.isNew);

		// closeForm();
		clear();
	};

	const postNewContact = async (n_contact, isNew = false) => {
		if (isNew) {
			console.log('Nuovo');
			const nC = await sendRequest(
				'contacts/newContact',
				'POST',
				{ n_contact },
				{ 'Content-Type': 'application/json' }
			);

			return { c: nC, isNew };
		} else {
			console.log('Aggiorno');
			const upC = await sendRequest(
				'contacts/updateContact',
				'POST',
				{ ...n_contact },
				{ 'Content-Type': 'application/json' }
			);

			return { c: upC, isNew };
		}
	};

	/**
	 * < Gestione post ----------------------------------------------
	 */

	const closeForm = () => {
		setContact(null);
		clear();
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			<div className={classes.background} onClick={closeForm} />
			<div className={classes.wrapper}>
				{Boolean(company) && <h2>{company.fullname}</h2>}
				{Boolean(nType) && <h2>{nType}</h2>}
				<div className={classes.data}>
					{setInputs()}

					{selectedOption === 'Persona' && (
						<Find
							url={`settings/contacts/roles/list/Persona`}
							setRes={setSelectedRefRole}
							label={'Ruolo'}
							inputId='personRole'
							initialValue={contact?.refRole?.name || ''}
							initValue={selectedRefRole || contact?.refRole?.name}
							driver={'name'}
							resName={null}
							isArray={true}
							width='100%'
							contStyle={evalGridStyle()}
						/>
					)}
					{selectedOption === 'Azienda' && (
						<Find
							url={`settings/contacts/roles/list/Azienda`}
							setRes={setSelectedRefRole}
							label={'Ambito'}
							inputId='companyRole'
							initialValue={contact?.refRole?.name || ''}
							initValue={selectedRefRole || contact?.refRole?.name}
							driver={'name'}
							resName={null}
							isArray={true}
							width='100%'
							contStyle={evalGridStyle()}
						/>
					)}

					{selectedOption === 'Persona' && !company && (
						<Find
							url={`contacts/activeCompanysList`}
							setRes={setSelectedRefCompany}
							label={'Azienda'}
							inputId='companyRef'
							initialValue={selectedRefCompany?.name || ''}
							initValue={selectedRefCompany || contact?.refCompany?.name}
							driver={'fullname'}
							resName={null}
							isArray={true}
							width='100%'
							contStyle={evalGridStyle()}
						/>
					)}
				</div>
				<div className={classes.buttons}>
					<Button clname='danger big' action={closeForm}>
						Chiudi
					</Button>
					<Button
						clname='confirm big'
						disabled={evalButtonDisabled()}
						action={post}
					>
						Salva
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default ContactForm;
