import React, { useState, useEffect } from 'react';

import classes from './InsertManual.module.css';

import LoadingSpinner from '../../../utils/LoadingSpinner';
import ErrorModal from '../../../utils/ErrorModal';
import Button from '../../../utils/Button/Button';
import Input from '../../../utils/Inputs/Input';

import { useForm } from '../../../hooks/form-hook';
import { VALIDATOR_MIN, VALIDATOR_MAX } from '../../../utils/validators';
import { useHttpClient } from '../../../hooks/http-hooks';

import { TotalMinToHourMin, roundHoursFromDate } from '../../../lib/functrions';

function InseretManual({
	clear,
	tagId,
	position,
	setTodayRecords,
	date,
	isExit,
	employee,
}) {
	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const [postingDate, setPostingDate] = useState(date);

	const [formState, inputHandler, setFormData] = useForm({
		hours: {
			value: '',
			isValid: false,
			el: 'input',
			type: 'number',
			label: 'Ore',
			validator: [VALIDATOR_MIN(0), VALIDATOR_MAX(24)],
			initValue: date.getHours(),
			initIsValid: true,
			errorText: 'Valore deve essere fra 0 e 24',
		},
		minutes: {
			value: '',
			isValid: false,
			el: 'input',
			type: 'number',
			label: 'Minuti',
			validator: [VALIDATOR_MIN(0), VALIDATOR_MAX(59)],
			initValue: date.getMinutes(),
			initIsValid: true,
			errorText: 'Valore deve essere fra 0 e 59',
		},
	});

	const [changed, setChanged] = useState(false);
	const [time, setTime] = useState(
		TotalMinToHourMin(
			roundHoursFromDate(
				postingDate,
				false,
				isExit,
				Number(isExit ? employee.roundsOUT : employee.roundsIN),
				employee?.weeklyEntrance &&
					employee?.weeklyEntrance[postingDate.getDay()]
			)
		)
	);

	useEffect(() => {
		let _date = new Date(postingDate);
		_date.setHours(formState.inputs.hours.value);
		_date.setMinutes(formState.inputs.minutes.value, 1);

		setTime(
			TotalMinToHourMin(
				roundHoursFromDate(
					_date,
					false,
					isExit,
					Number(isExit ? employee.roundsOUT : employee.roundsIN),
					employee?.weeklyEntrance && employee?.weeklyEntrance[_date.getDay()]
				)
			)
		);
		// console.log(
		// 	postingDate.getMinutes() +
		// 		' - ' +
		// 		_date.getMinutes() +
		// 		' -- ' +
		// 		postingDate.getHours() +
		// 		' - ' +
		// 		_date.getHours() +
		// 		(postingDate.getMinutes() != _date.getMinutes() ||
		// 			postingDate.getHours() != _date.getHours())
		// );
		if (
			postingDate.getMinutes() !== _date.getMinutes() ||
			postingDate.getHours() !== _date.getHours()
		) {
			setChanged(true);
		} else {
			setChanged(false);
		}
	}, [formState]);

	// useEffect(() => {
	// 	console.log({ changed });
	// }, [changed]);

	const postData = async e => {
		e.preventDefault();
		const today = new Date(date);
		let year = today.getFullYear();
		let month = '0' + (today.getMonth() + 1);
		let day = '0' + today.getDate();

		let h = '0' + formState.inputs.hours.value;
		let m = '0' + formState.inputs.minutes.value;

		const postingDate = new Date(
			`${year}-${month.slice(-2)}-${day.slice(-2)}T${h.slice(-2)}:${m.slice(
				-2
			)}:01`
		);

		const record = await sendRequest(
			'attendance/insertManualRecord',
			'POST',
			{
				dateTime: postingDate,
				tagId: tagId,
				latitude: position?.coords?.latitude,
				longitude: position?.coords?.longitude,
				accuracy: position?.coords?.accuracy,
				altitude: position?.coords?.altitude,
				altitudeAccuracy: position?.coords?.altitudeAccuracy,
				heading: position?.coords?.heading,
				speed: position?.coords?.speed,
				timestamp: position?.timestamp,
				changed: changed,
			},
			{
				'Content-Type': 'application/json',
			}
		);

		setTodayRecords(currentRecords => {
			let newRecords = [...currentRecords, record];
			newRecords.sort((a, b) => new Date(a.date) - new Date(b.date));
			return [...newRecords];
		});

		clear(true);
	};

	const closeCard = e => {
		e.preventDefault();
		clear();
	};

	const setInputs = () => {
		let inputs = formState.inputs;
		let keys = Object.keys(formState.inputs);

		// console.log(wData);
		const inputsVisual = keys.map(k => {
			let i = inputs[k];
			return (
				<Input
					key={k}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
				/>
			);
		});
		return inputsVisual;
	};

	return (
		<React.Fragment>
			{isLoading && <LoadingSpinner asOverlay />}
			{error && <ErrorModal error={error} onClear={clearError} />}
			<div className={classes.background} onClick={clear} />
			<div className={classes.container}>
				<div className={classes.header}>
					{isExit ? 'Uscita' : 'Entrata'} rilevata:
					<p className={classes.realTime}>{time}</p>
				</div>
				<div className={classes.form}>
					{setInputs()}
					<Button
						clname='danger'
						onClick={closeCard}
						style={{ width: 25 + '%', fontSize: 20 + 'px' }}
					>
						Annulla
					</Button>
					<Button
						clname='confirm'
						style={{
							// width: 40 + '%',
							fontSize: 20 + 'px',
							minWidth: 'fit-content',
						}}
						disabled={!formState.isValid}
						onClick={postData}
					>
						Conferma
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default InseretManual;
