import React, { useEffect, useState, useContext } from 'react';

import classes from './ContactCard.module.css';

import { ReportCxt } from '../../../../../../context/reportCxt';

function ContactCard({ contact, updatePartecipants }) {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const [isAttending, setIsAttending] = useState(false);

	const chageAttending = () => {
		setIsAttending(!isAttending);
		const action = !isAttending ? 'add' : 'remove';
		updatePartecipants(action, contact);
	};

	const evalIsAttending = () => {
		let testAttending = false;

		const list = reportState?.reportHeader?.refPartecipants;

		if (list?.length > 0) {
			list?.map(p => {
				if (p?.refContatto?._id == contact?.refContatto?._id) {
					testAttending = true;
				}
			});
		}
		setIsAttending(Boolean(testAttending));
	};

	useEffect(() => {
		if (reportState) {
			evalIsAttending();
		}
	}, [reportState]);

	return (
		<div
			className={`${classes.wrapper} ${isAttending && classes.active}`}
			onClick={!reportState?.reportHeader?.isClosed && chageAttending}
		>
			<div className={classes.header}>
				{contact?.refContatto?.jobTitle} {contact?.refContatto?.fullname}
			</div>
			<div className={classes.role}>
				{contact?.refSiteRole?.name || <br></br>}
			</div>
			{/* <div className={`${classes.state}`}>
				{isAttending ? 'Presente' : <br></br>}
			</div> */}
		</div>
	);
}

export default ContactCard;
