import React, { useState } from 'react';

import classes from './CloseRecord.module.css';

import Button from '../../../../../../../../utils/Button/Button';
import { useForm } from '../../../../../../../../hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../../../../../../../utils/validators';

function CloseRecord({ data, closeRecord, clear }) {
	const creatinDate = new Date(
		data?.refReportHeader?.creationDate
	).toLocaleDateString('it-IT', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});

	const [formState, inputHandler, setFormData, setInputs] = useForm({
		observation: {
			value: `Si conferma la risoluzione di quanto rilevato in data ${creatinDate}`,
			isValid: false,
			el: 'textarea',
			type: 'textarea',
			label: 'Risoluzione',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: false,
			initValue: `Si conferma la risoluzione di quanto rilevato in data ${creatinDate}`,
			rows: 6,
			style: { height: 'auto' },
		},
	});

	const [inputsVisuals, setInputsVisuals] = useState(setInputs(formState));

	const postData = () => {
		data.resolution = formState.inputs.observation.value;
		data.resolutionDate = new Date();
		closeRecord(data);
	};

	const closeForm = () => {
		clear();
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={closeForm}></div>
			<div className={classes.wrapper}>
				<div className={classes.text}>
					<h2>Osservazione</h2>
					{data.observation}
				</div>
				<div>
					<h2>Prescrizione</h2>
					{data.prescription}
				</div>
				<div>{inputsVisuals}</div>
				<div className={classes.buttons}>
					<Button clname={'danger big'} onClick={closeForm}>
						Annulla
					</Button>
					<Button clname={'confirm big'} onClick={postData}>
						Salva
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default CloseRecord;
