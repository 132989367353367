import React, { useState, useEffect } from 'react';

import classes from './ModelSelection.module.css';

import IconButton from '../../../../utils/IconButton';

import { reportTypes, reportModels } from '../ReportsData';

// function ModelSelection({ reportTypes, reportModels, selectModel }) {
function ModelSelection({ selectModel }) {
	const [selectedType, setSelectedType] = useState(null);
	const [selectedModel, setSelectedModel] = useState(null);
	const [confirmColor, setConfirmColor] = useState('var(--ENDEDtext)');

	const [typeVisuals, setTypeVisuals] = useState(null);
	const [modelVisuals, setModelVisuals] = useState(null);

	const evalVisuals = () => {
		evalTypeVisuals();
		if (selectedType) {
			evalModelVisuals();
		}
	};

	const evalTypeVisuals = () => {
		const _vT = reportTypes.map(type => {
			const typeSelection = () => {
				setSelectedType(type);
			};

			let cl_selected = '';
			if (selectedType?.code === type.code) {
				cl_selected = classes.selected;
			}

			return (
				<div
					key={type.code}
					className={`${classes.tCard} ${cl_selected}`}
					onClick={typeSelection}
				>
					{type.name}
				</div>
			);
		});

		setTypeVisuals(_vT);
	};

	const evalModelVisuals = () => {
		const _vM = [];
		reportModels.map(model => {
			if (selectedType?.code === model.type) {
				const typeSelection = () => {
					setSelectedModel(model);
				};

				let cl_selected = '';
				if (selectedModel?.code === model.code) {
					cl_selected = classes.selected;
				}
				_vM.push(
					<div
						key={model.code}
						className={`${classes.mCard} ${cl_selected}`}
						onClick={typeSelection}
					>
						{model.name}
					</div>
				);
			}
		});

		if (_vM.length >= 1) {
			setModelVisuals(_vM);
		} else {
			setNoModel('Nessun modello per la tipologia selezionata');
		}
	};

	const setNoModel = text => {
		if (!text) {
			text = 'Seleziona tipologia di verbale';
		}
		setModelVisuals(<div className={classes.notSelected}>{text}</div>);
		setSelectedModel(null);
	};

	useEffect(() => {
		evalVisuals();
		if (selectedModel) {
			setSelectedModel(null);
		}
	}, [selectedType]);

	useEffect(() => {
		if (selectedModel) {
			setConfirmColor('var(--ONGOING)');
		} else {
			setConfirmColor('var(--ENDEDtext)');
		}
		evalModelVisuals();
	}, [selectedModel]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.group}>
				<p>Tipo verbale:</p>
				<div className={classes.types}>{typeVisuals}</div>

				<p>Modello:</p>
				<div className={classes.models}>{modelVisuals}</div>
			</div>

			<div className={classes.buttons}>
				<IconButton
					text={'check_circle'}
					style={{
						fontSize: '3rem',
						color: confirmColor,
						textShadow: '0 0 4px ' + confirmColor,
					}}
					action={() => selectModel(selectedModel.code)}
				/>
			</div>
		</div>
	);
}

export default ModelSelection;
