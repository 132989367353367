import React, { useState, useEffect, useContext } from 'react';
import ReactDom from 'react-dom';

import classes from './Cartellino.module.css';

import { useHttpClient } from '../../../hooks/http-hooks';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import ErrorModal from '../../../utils/ErrorModal';

import {
	dmyFromDateString,
	roundHoursFromDate,
	TimeFromDateString,
	TotalMinToHourMin,
} from '../../../lib/functrions';

import IconButton from '../../../utils/IconButton';
import Button from '../../../utils/Button/Button';

import EditRefund from '../../Presenze/Cartellini/comps/EditRefund';
import JustificationHandler from '../../Presenze/Cartellini/comps/JustificationHandler';

import InsertRecord from '../../Presenze/Cartellini/comps/InsertRecord';

function Cartellino({ e, clear, tagId }) {
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [homePage, setHomePage] = useState(null);
	const [tagRecords, setTagRecords] = useState(null);
	const [justificationsRecords, setJustificationsRecords] = useState(null);
	const [refundRecords, setRefundRecords] = useState(null);

	const [changes, setChanges] = useState(false);

	const getTagRecords = async () => {
		const records = await sendRequest(
			`attendance/getRecords/`,
			'POST',
			{ date: new Date(), tagId: tagId, getArchivedDate: true }, // timeSpan: 7 },
			{ 'Content-Type': 'application/json' }
		);
		setTagRecords(records);
	};

	const getRefunds = async () => {
		const records = await sendRequest(
			`attendance/getRefundRecords/`,
			'POST',
			{ date: new Date(), tagId: tagId, getArchivedDate: true }, //  timeSpan: 7 },
			{ 'Content-Type': 'application/json' }
		);
		setRefundRecords(records);
	};

	const getJustifications = async () => {
		const records = await sendRequest(
			`attendance/getJustificationRecords`,
			'POST',
			{ date: new Date(), tagId: tagId, getArchivedDate: true }, //  timeSpan: 7 },
			{ 'Content-Type': 'application/json' }
		);
		setJustificationsRecords(records);
	};

	const evalJustification = (wMin, limit, date) => {
		let _cont;
		let cl = `${classes.totRow} ${classes.justification}`;
		let clChild = ` ${classes.justification} ${classes.totRow__desc}`;
		let a = null;
		justificationsRecords?.filter(just => {
			if (new Date(date).getDate() === new Date(just.date).getDate()) {
				a = just;
			}
		});
		if (wMin >= 0 && wMin < limit * 60 && limit !== 0) {
			if (!a) {
				_cont = (
					<div
						className={classes.insertJustiification}
						onClick={() =>
							setJustificationData({ currData: a, currDate: date })
						}
					>
						<IconButton text={'edit'} />
					</div>
				);
			} else {
				_cont = (
					<div
						onClick={() =>
							setJustificationData({ currData: a, currDate: date })
						}
					>
						<div className={clChild}>Giustificativo: </div>
						{a.justificationId.code} {TotalMinToHourMin(limit * 60 - wMin)}
					</div>
				);
			}
		} else {
			_cont = '';
		}
		return <div className={cl}>{_cont}</div>;
	};

	//----------------------------------------------------
	//>>>>>>>>>> Refunds
	//----------------------------------------------------

	const [refundData, setRefundData] = useState(null);
	const [showEditRefund, setShowEditRefund] = useState(false);
	const editRefundHandler = (reload = false) => {
		if (showEditRefund) {
			setRefundData(null);
		}
		setShowEditRefund(!showEditRefund);
		if (reload) {
			if (refundData[0]?.lineDate == new Date().toLocaleDateString('it-IT')) {
				setChanges(true);
			}

			getRefunds();
		}
	};

	useEffect(() => {
		if (refundData) {
			editRefundHandler();
		}
	}, [refundData]);

	const editRefundForm = () => {
		const form = (
			<EditRefund
				r_data={refundData}
				clear={editRefundHandler}
				employee={{ ...e, tagId: tagId }}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	const evalRefunds = (type, date) => {
		const workingDate = new Date(date);

		const lineDate = workingDate.toLocaleString('it-IT').split(',')[0];
		let value = 0;

		const filteredRefund = refundRecords?.filter(rec => {
			return rec.type === type;
		});

		const data = filteredRefund?.map(refund => {
			let recDate = new Date(refund.date).toLocaleString('it-IT').split(',')[0];
			if (refund.type === type && lineDate === recDate) {
				value += refund.value;
				refund.stringDate = recDate;
				return refund;
			}
			return null;
		});

		data?.unshift({ lineDate: lineDate, type: type, temp: true });

		return (
			<div
				className={`${classes.dailyTime_time} ${classes.totRow}`}
				onClick={() => {
					setRefundData(data);
				}}
			>
				{`${value > 0 ? value.toLocaleString() : ''} ${
					type === 'trip' ? 'km' : '€'
				}`}
			</div>
		);
	};

	// Refunds
	//-----------------------------------------------------------------------------------------

	//----------------------------------------------------
	//>>>>>>>>>> Justifications
	//----------------------------------------------------

	const [justificataionData, setJustificationData] = useState(null);
	const [showHandleJustification, setShowHandleJustification] = useState(false);
	const handleJustificationHandler = (reload = false) => {
		if (showHandleJustification) {
			setJustificationData(null);
		}
		setShowHandleJustification(!showHandleJustification);
		if (reload) {
			getJustifications();
		}
	};

	const postNewJustification = async data => {
		if (data.delete) {
			console.log('Elimino ');
			await sendRequest(
				`attendance/deleteJustification/`,
				'POST',
				{
					justificationId: data.prevData._id,
				},
				{ 'Content-Type': 'application/json' }
			);
		} else {
			if (data.prevData) {
				let jstId = data.prevData.justificationId._id;
				if (data.justification._id) {
					jstId = data.justification._id;
				}

				await sendRequest(
					`attendance/editJustification/`,
					'POST',
					{
						justificationId: data.prevData._id,
						jstModelId: jstId,
						note: data.note,
					},
					{ 'Content-Type': 'application/json' }
				);
			} else {
				await sendRequest(
					`attendance/newJustification/`,
					'POST',
					{
						date: data.date,
						jstModelId: data.justification._id,
						tagId: tagId,
						note: data.note,
					},
					{ 'Content-Type': 'application/json' }
				);
			}
		}
		handleJustificationHandler(true);
	};

	const justificationForm = () => {
		const form = (
			<JustificationHandler
				jstData={justificataionData}
				clear={handleJustificationHandler}
				postFunction={postNewJustification}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	useEffect(() => {
		if (justificataionData) {
			handleJustificationHandler();
		}
	}, [justificataionData]);

	const getHomePage = () => {
		const today = new Date();
		const endDate = new Date();
		const startDate = new Date(tagRecords[0].date);
		// startDate.setDate(endDate.getDate() - 7);
		startDate.setDate(1);
		startDate.setHours(0);
		endDate.setMinutes(1);
		endDate.setSeconds(0);

		endDate.setHours(23);
		endDate.setMinutes(59);
		endDate.setSeconds(59);
		endDate.setMilliseconds(990);

		const dayRows = [];

		for (
			let filterDate = startDate.getTime();
			filterDate <= endDate.getTime();
			filterDate += 24 * 60 * 60 * 1000
		) {
			let isExit = false;
			let fDate = dmyFromDateString(new Date(filterDate));
			let dayRow = [];
			tagRecords.map(re => {
				let tDate = dmyFromDateString(re.date);
				if (tDate == fDate) {
					let rDate = dmyFromDateString(re.date);
					if (rDate == fDate) {
						re.isExit = isExit;
						dayRow.push(re);
						isExit = !isExit;
					}
					return;
				}
			});
			let lastRecordDate;
			let workedMins = 0;
			let movements = dayRow.map(m => {
				let recordDate = new Date(m.date);
				if (
					dmyFromDateString(lastRecordDate).toString() !=
					dmyFromDateString(recordDate).toString()
				) {
					lastRecordDate = new Date(recordDate);
				}

				if (!m.isExit) {
					workedMins -= roundHoursFromDate(
						recordDate,
						false,
						m.isExit,
						e.roundsIN,
						e?.weeklyEntrance && e?.weeklyEntrance[recordDate.getDay()]
					);
				} else {
					workedMins += roundHoursFromDate(
						recordDate,
						false,
						m.isExit,
						e.roundsOUT
					);
				}

				return m;
			});

			const dayOfTheWeek = new Date(filterDate).getDay();
			const extraLimit = e?.weekStructure[dayOfTheWeek];
			let rowExtra = workedMins - extraLimit * 60;

			dayRows.push(
				<div className={`${classes.dailyRow} ${classes[`day${dayOfTheWeek}`]}`}>
					<div className={classes.dailyDate}>{fDate}</div>
					<div className={classes.dailyTime}>
						{movements.map(m => {
							return (
								<div className={`${classes.dailyTime_time}`}>
									{m.isExit ? 'U: ' : 'E: '} {TimeFromDateString(m.date)}
								</div>
							);
						})}
					</div>
					<div
						className={`${classes.totRow} ${
							Number(workedMins) < 0 ? classes.totRowError : ''
						} ${Number(workedMins) == 0 ? classes.totRowHidden : ''}
							`}
					>
						<div className={`${classes.totRow__desc}`}>Totale: </div>
						{workedMins < 0 ? 'Errore' : TotalMinToHourMin(workedMins)}
					</div>
					<div
						className={`
							${classes.totRow}
							${Number(rowExtra) <= 0 ? classes.totRowHidden : ''}
							`}
					>
						<div
							className={`
							${classes.totRow__desc}
							${Number(rowExtra) <= 0 ? classes.totRowHidden : ''}
							`}
						>
							Extra:{' '}
						</div>
						{TotalMinToHourMin(rowExtra)}
					</div>

					{evalJustification(workedMins, extraLimit, filterDate)}
					{evalRefunds('expense', filterDate)}
					{evalRefunds('trip', filterDate)}
					<div className={`${classes.totRow} ${classes.addNewRecord}`}>
						<IconButton
							className={''}
							text='add_circle'
							// todo:
							action={() => {
								setCurrentDate({
									date: fDate,
									employee: { tagId: tagId },
									edit: false,
								});
							}}
						/>
					</div>
				</div>
			);
			workedMins = 0;
		}

		dayRows.unshift(
			<div key={'dailyRowHeader'} className={classes.dailyRowHeader}>
				<div className={classes.dailyDate}>Data</div>

				<div className={classes.dailyTime}>Passaggi</div>

				<div className={classes.totRow}>Totale</div>
				<div className={classes.totRow}>Extra</div>
				<div className={`${classes.totRow} ${classes.justification}`}>
					Giust.
				</div>
				<div className={classes.totRow}>Spese</div>
				<div className={classes.totRow}>KM</div>
			</div>
		);

		let card = (
			<div key={e._id} id={e._id} className={classes.empWrapper}>
				<div className={classes.employeeCard}>
					<div className={classes.cardRows}>{dayRows}</div>
				</div>
			</div>
		);

		const visual = <div className={classes.attendance}>{card}</div>;
		setHomePage(visual);
	};

	//-----------------------------------------
	//Aggiungi timbratura
	//-----------------------------------------

	const [currentDate, setCurrentDate] = useState(null);

	const [showInsertRecord, setShowInsertRecord] = useState(false);
	const insertRecordHandler = (reload = false) => {
		setShowInsertRecord(!showInsertRecord);
		if (reload) {
			getTagRecords();
		}
	};

	const addNewRecord = () => {
		const newRecordForm = (
			<InsertRecord
				clear={insertRecordHandler}
				wData={currentDate}
				userInput={true}
			/>
		);

		return ReactDom.createPortal(
			newRecordForm,
			document.getElementById('modal-hook')
		);
	};

	useEffect(() => {
		if (currentDate) {
			insertRecordHandler();
		}
	}, [currentDate]);

	//-----------------------------------------

	useEffect(() => {
		getTagRecords();
		getRefunds();
		getJustifications();
	}, []);

	useEffect(() => {
		if (tagRecords && justificationsRecords && refundRecords) {
			getHomePage();
		}
	}, [tagRecords, justificationsRecords, refundRecords]);

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			{showInsertRecord && addNewRecord()}
			{showEditRefund && editRefundForm()}
			{showHandleJustification && justificationForm()}
			<div className={classes.background} onClick={() => clear(changes)} />
			<div className={classes.wrapper}>
				{homePage}
				<div className={classes.buttons} onClick={() => clear(changes)}>
					<Button clname={'danger'}>Chiudi</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Cartellino;
