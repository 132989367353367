import React from 'react';

import classes from './RegOpenLine.module.css';
import { reportTypes, reportModels } from '../Report/ReportsData';
import IconButton from '../../../utils/IconButton';

function RegOpenLine({ line, action }) {
	const today = new Date();
	const lineDate = new Date(line.dueDate);

	const msDay = 24 * 60 * 60 * 1000;

	let cl = 'standard';

	if (today - lineDate > msDay * -3) {
		cl = 'alert';
	}
	if (today - lineDate > msDay * 6) {
		cl = 'danger';
	}

	if (line.isClosed) {
		cl = 'closed';
	}

	let rType = '';
	reportModels.map(m => {
		if (m.code === line.refReportType) {
			reportTypes.map(t => {
				if (t.code === m.type) {
					rType = t.name + ' - ' + m.name;
				}
			});
		}
	});

	const selectLine = () => {
		action(line);
	};

	return (
		<div key={line._id} className={`${classes.wrapper}`}>
			<div className={classes.header}>
				<div className={classes.data}>
					<p className={`${classes.dueDate} ${classes[cl]}`}>
						{lineDate.toLocaleDateString('it-IT', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
						})}
						{line.isClosed
							? ' | OK'
							: ` | ${Math.floor((today - lineDate) / msDay)} gironi`}
					</p>
					<p>{rType}</p>
					<p className={classes.company}>{line.refCompany.fullname}</p>
				</div>
				{action && (
					<div className={classes.buttons}>
						<IconButton text={'check_circle'} action={selectLine} />
					</div>
				)}
			</div>
			<div>{line.prescription}</div>
		</div>
	);
}

export default RegOpenLine;
