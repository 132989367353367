import React, { useContext, useState, useEffect } from 'react';

import classes from './EditRecord.module.css';

import IconButton from '../../../../../../../../utils/IconButton';

import { ReportCxt } from '../../../../../../../../context/reportCxt';

import { useForm } from '../../../../../../../../hooks/form-hook';
import {
	VALIDATOR_NO,
	VALIDATOR_REQUIRE,
} from '../../../../../../../../utils/validators';
import Find from '../../../../../../../../utils/Inputs/Find';

import { useHttpClient } from '../../../../../../../../hooks/http-hooks';
import ErrorModal from '../../../../../../../../utils/ErrorModal';
import LoadingSpinner from '../../../../../../../../utils/LoadingSpinner';

import Photocamera from '../../../../../../../../utils/Photocamera';

function EditRecord({ lineData, clear }) {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const [formState, inputHandler, setFormData, setInputs] = useForm({
		observation: {
			value: lineData.observation,
			isValid: false,
			el: 'textarea',
			type: 'textarea',
			label: 'Descrizione attività',
			validator: [VALIDATOR_NO()],
			initIsValid: lineData.observation ? true : false,
			initValue: lineData.observation,
			rows: 6,
			style: { height: 'auto' },
			// contStyle: evalGridStyle(),
		},
		prescription: {
			value: lineData.prescription,
			isValid: true,
			el: 'textarea',
			type: 'textarea',
			label: 'Prescrizione',
			validator: [VALIDATOR_NO()],
			initIsValid: lineData.prescription ? true : false,
			initValue: lineData.prescription,
			rows: 6,
			style: { height: 'auto' },
			// contStyle: evalGridStyle(),
		},
		dueDate: {
			value: '',
			isValid: false,
			el: 'date',
			type: 'date',
			label: 'Termine',
			validator: [VALIDATOR_REQUIRE()],
			initValue: '',
			initIsValid: true,
		},
	});

	const [inputsVisuals, setInputsVisuals] = useState(setInputs(formState));

	/**-------------------------------------- */
	/**> Picture ---------------------------- */

	const [imageUrl, setImageUrl] = useState({
		url: lineData.imageUrl,
		imageMetadata: { ...lineData?.imageMetadata },
	});
	const [imagePreview, setImagePreview] = useState('');
	const [fileData, setFileData] = useState(null);

	const displayPicture = () => {
		return (
			<div className={classes.addImage}>
				<div className={classes.imgButtons}>
					<Photocamera
						imagePreview={imagePreview}
						setImagePreview={setImagePreview}
						setFileData={setFileData}
					/>
				</div>
				{imageUrl && (
					<img
						id='imagePreview'
						alt="Preview dell'immagine"
						src={imageUrl.url}
						style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
					/>
				)}
				{imageUrl && (
					<div className={classes.imgDescription}>
						<p>{imageUrl?.metadata?.name}</p>
					</div>
				)}
			</div>
		);
	};

	useEffect(() => {
		if (imagePreview && fileData) {
			savePicture();
		}
	}, [imagePreview, fileData]);

	const savePicture = async () => {
		const data = {
			lastModified: fileData.lastModified,
			lastModifiedDate: fileData.lastModifiedDate,
			name: fileData.name,
			size: fileData.size,
			type: fileData.type,
			folder: reportState.reportHeader.imgFolderId,
		};

		const pic = await sendRequest(
			'report/lines/picture_ADD',
			'POST',
			{
				image: imagePreview,
				data: data,
			},
			{ 'Content-Type': 'application/json' }
		);

		console.log(pic);
		setImageUrl(pic);
	};

	/**< Picture ---------------------------- */
	/** ------------------------------------- */

	const closeForm = () => {
		clear();
	};

	const saveData = async () => {
		console.log('Confirm');
		console.log({ reportState });

		const l_observation = formState.inputs.observation.value || '';
		const l_prescription = formState.inputs.prescription.value || '';
		const l_dueDate = formState.inputs.dueDate.value || '';

		const r_line = {
			observation: l_observation,
			prescription: l_prescription,

			// refTarget: [{ type: Schema.Types.ObjectId, ref: 'Contact' }],
			// refReferent: { type: Schema.Types.ObjectId, ref: 'Contact' },

			// severity: { type: Number },
		};

		if (l_dueDate) {
			r_line.dueDate = l_dueDate;
		}

		if (imageUrl) {
			r_line.imageUrl = imageUrl.url;
			r_line.imageMetadata = imageUrl.metadata;
		}

		console.log({ r_line });

		const data = await sendRequest(
			`report/line/update`,
			'POST',
			{ lineData: r_line, id: lineData._id },
			{ 'Content-Type': 'application/json' }
		);

		rActions?.updateReportLines(data);
		closeForm();
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			<div className={classes.background} onClick={clear}></div>
			<div className={classes.wrapper}>
				<div className={classes.header}></div>
				<div className={classes.body}>
					{displayPicture()}
					{inputsVisuals}
				</div>
				<div className={classes.buttons}>
					<IconButton
						text={'cancel'}
						style={{ color: 'var(--STOPED)', padding: 0 }}
						action={closeForm}
					/>

					<span
						style={
							formState.isValid || imageUrl
								? { color: 'var(--ONGOING)' }
								: { color: 'gray' }
						}
					>
						<IconButton
							text={'check_circle'}
							style={{ padding: 0 }}
							action={saveData}
							enabled={formState.isValid}
						/>
					</span>
				</div>
			</div>
		</React.Fragment>
	);
}

export default EditRecord;
