import React, { useState } from 'react';

import classes from './AddGuest.module.css';

import Button from '../../../../../../../utils/Button/Button';

import { useForm } from '../../../../../../../hooks/form-hook';
import {
	VALIDATOR_NO,
	VALIDATOR_REQUIRE,
} from '../../../../../../../utils/validators';

function AddGuest({ action, clear }) {
	const [formState, inputHandler, setFormData, setInputs] = useForm({
		fullname: {
			value: '',
			isValid: false,
			el: 'input',
			type: 'text',
			label: 'Nome Cognome',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: false,
			initValue: '',
		},
		email: {
			value: '',
			isValid: true,
			el: 'input',
			type: 'text',
			label: 'Email',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: '',
		},
		phoneNumber: {
			value: '',
			isValid: true,
			el: 'input',
			type: 'text',
			label: 'Numero di telefono',
			validator: [VALIDATOR_NO()],
			initIsValid: true,
			initValue: '',
		},
		role: {
			value: '',
			isValid: false,
			el: 'input',
			type: 'text',
			label: 'Ruolo',
			validator: [VALIDATOR_REQUIRE()],
			initIsValid: false,
			initValue: '',
		},
	});

	const [inputVisuals, setInputVisuals] = useState(setInputs(formState));

	const insertGuest = () => {
		const nGuest = {
			fullname: formState.inputs.fullname.value,
			email: formState.inputs.email.value,
			phoneNumber: formState.inputs.phoneNumber.value,
			role: formState.inputs.role.value,
			refCompany: 'guest',
			idx: Date.now(),
		};
		action(nGuest);
		closeForm();
	};

	const closeForm = () => {
		clear();
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={closeForm} />
			<div className={classes.wrapper}>
				<div className={classes.header}>Aggiungi firmatario</div>
				<div className={classes.inputs}>{inputVisuals}</div>
				<div className={classes.buttons}>
					<Button clname='danger big' action={closeForm}>
						Annulla
					</Button>
					<Button
						clname='confirm big'
						disabled={!formState.isValid}
						action={insertGuest}
					>
						Inserisci
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default AddGuest;
