import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import ReactDom from 'react-dom';

import classes from './Cantieri.module.css';

import { UserCxt } from '../../context/UserCxt';

import { useHttpClient } from '../../hooks/http-hooks';
import ErrorModal from '../../utils/ErrorModal';
import LoadingSpinner from '../../utils/LoadingSpinner';

import Button from '../../utils/Button/Button';

import SiteCard from './Comps/SiteCard';
import EditSite from './SitePage/DatiCantiere/Comps/EditSite';

function Cantieri() {
	const { clearError, error, isLoading, sendRequest } = useHttpClient();
	const [isLocaleLoading, setIsLocaleLoading] = useState(false);
	const user = useContext(UserCxt);

	/**
	 * ----------------------------------------------
	 * > Caricamento iniziale
	 * ----------------------------------------------
	 */

	const [siteList, setSiteList] = useState([]);
	const [siteVisuals, setSiteVisuals] = useState(null);
	const [selectActiveSites, setSelectActiveSites] = useState(false);

	const getSiteList = async () => {
		// console.log(user.pCloudToken);
		// let tokenRefresh = false;
		// if (!user.pCloudToken) {
		// 	console.log('No data');
		// 	tokenRefresh = true;
		// }

		// if (tokenRefresh) {
		// 	const token = await sendRequest('site/startUp');
		// 	user.pCloudToken = token;
		// }

		const data = await sendRequest('site/activeSites');
		console.log('carico dati cantiere');

		// data.map(async c => {
		// 	console.log(c.coverImgId);
		// 	if (c.coverImgId) {
		// 		// let url_f = `https://api.pcloud.com/getthumb?auth=${user.pCloudToken.value.auth}&fileid=${c.coverImgId}&size=800x800`;

		// 		console.log(url_f);

		// 		const fileData = await fetch(url_f);
		// 		console.log({ fileData });

		// 		const file = await fileData.json();
		// 		console.log({ file });

		// 		if (file.result !== 7010) {
		// 			const url = `https://${file.hosts[0]}${file.path}`;
		// 			console.log({ url });
		// 			c.coverImgId = url;
		// 		}
		// 	}
		// });

		setSiteList(data);
	};

	const changeSitesList = async () => {
		const url = selectActiveSites
			? 'contacts/activeContactsList'
			: 'contacts/inactiveContactsList';
		const data = await sendRequest(url);

		console.log('carico dati cantiere');

		setSiteList(data);
		setSelectActiveSites(!selectActiveSites);
	};

	const evalSiteVisuals = () => {
		const _v = siteList.map(s => {
			return (
				<NavLink key={s._id} className={classes.articleCard} to={s._id}>
					<SiteCard key={s._id} selectSite={setSelectedSite} site={s} />
				</NavLink>
			);
		});
		setSiteVisuals(_v);
	};

	useEffect(() => {
		evalSiteVisuals();
	}, [siteList]);

	useEffect(() => {
		getSiteList();
	}, []);

	/** < Caricamento iniziale */

	/**
	 * ----------------------------------------------
	 * > Nuovo cantiere
	 * ----------------------------------------------
	 */

	const [selectedSite, setSelectedSite] = useState(null);
	const [showSiteForm, setShowSiteForm] = useState(false);

	const handleShowSiteForm = () => {
		setShowSiteForm(!showSiteForm);
	};

	const postSiteData = () => {
		if (selectedSite) {
			console.log('Aggiorno');
		} else {
			console.log('Nuovo');
		}
	};

	const openSiteForm = () => {
		const form = (
			<EditSite
				clear={handleShowSiteForm}
				action={postSiteData}
				site={selectedSite}
				setSite={setSelectedSite}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	useEffect(() => {
		if (selectedSite) {
			handleShowSiteForm();
		}
	}, [selectedSite]);

	/** < Nuovo cantiere */

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{(isLoading || isLocaleLoading) && <LoadingSpinner asOverlay />}
			{showSiteForm && openSiteForm()}
			<div className={classes.wrapper}>
				<h1>Cantieri</h1>
				<div className={classes.buttons}>
					<Button
						clname={'confirm big'}
						//action={handleShowSiteForm}
					>
						<NavLink className={classes.articleCard} to={'new'}>
							Nuovo cantiere
						</NavLink>
					</Button>
					<div className={classes.filters}></div>
				</div>
				<div className={classes.cardsWrapper}>{siteVisuals}</div>
			</div>
		</React.Fragment>
	);
}

export default Cantieri;
