import React, { useContext, useState, useEffect } from 'react';

import classes from './ActiveWorks.module.css';

import { ReportCxt } from '../../../../../../../context/reportCxt';

import ReportLine from '../../../_parts/ReportLine/ReportLine';
import NewRecord from './NewRecord/NewRecord';

import IconButton from '../../../../../../../utils/IconButton';

function ActiveWorks({ deleteReportLine }) {
	const { reportState, ...rActions } = useContext(ReportCxt);

	const [lastCompanySelected, setLastCompanySelected] = useState(null);

	const [reportLinseVisuals, setReportLinseVisuals] = useState(null);
	const [addReportLine, setAddReportLine] = useState(false);

	const evalReportsLinesVisuals = () => {
		let lastRefCompany = null;
		let companyCard = [];
		const _v = [];

		reportState.reportLines?.map(l => {
			if (lastRefCompany?._id != l?.refCompany?._id) {
				if (lastRefCompany) {
					_v.push(
						<div key={lastRefCompany._id} className={classes.companyCard}>
							<p className={classes.companyName}>{lastRefCompany?.fullname}</p>
							{companyCard}
						</div>
					);
				}
				companyCard = [];
			}
			lastRefCompany = l.refCompany;
			companyCard.push(
				<ReportLine
					key={l._id}
					lineData={l}
					deleteReportLine={deleteReportLine}
				/>
			);
		});

		lastRefCompany &&
			_v.push(
				<div className={classes.companyCard}>
					<p className={classes.companyName}>{lastRefCompany?.fullname}</p>
					{companyCard}
				</div>
			);

		setReportLinseVisuals(_v);
	};

	const handleAddReportLine = () => {
		if (addReportLine) {
			evalReportsLinesVisuals();
		}
		setAddReportLine(!addReportLine);
	};

	useEffect(() => {
		evalReportsLinesVisuals();
	}, [reportState]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.linesWrapper}>
				{reportState.reportLines.length > 0 && reportLinseVisuals}
			</div>
			{addReportLine && (
				<NewRecord
					siteData={reportState.site}
					clear={handleAddReportLine}
					lastCompanySelected={lastCompanySelected}
					setLastCompanySelected={setLastCompanySelected}
				/>
			)}
			{!reportState?.reportHeader?.isClosed && (
				<div className={classes.noPrint}>
					<IconButton
						text={'add_circle'}
						style={{ fontSize: '2rem', color: 'var(--activeLink)' }}
						action={handleAddReportLine}
					/>
				</div>
			)}
		</div>
	);
}

export default ActiveWorks;
