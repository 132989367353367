import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import classes from './Giustificativo.module.css';

import { useHttpClient } from '../../../../hooks/http-hooks';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import ErrorModal from '../../../../utils/ErrorModal';
import IconButton from '../../../../utils/IconButton';

import { VALIDATOR_REQUIRE, VALIDATOR_NO } from '../../../../utils/validators';

import AddNewSetting from './AddNewSetting';

function Giustificativo() {
	const [dataJustifications, setDataJustifications] = useState(null);
	const [visualJustifications, setVisualJustifications] = useState(null);
	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const getDataCausali = async () => {
		const rData = await sendRequest(`attSettings/justificationsList`);
		setDataJustifications(rData);
	};

	useEffect(() => {
		if (dataJustifications) {
			const v = dataJustifications.map(r => {
				return (
					<div
						key={r._id}
						className={`${classes.row} ${
							r.isActive == false && classes.disabled
						}`}
						title={r.description || 'Nessuna nota utilizzo presene'}
					>
						<div className={classes.row__edit}>
							<IconButton text={'edit'} action={() => editJustification(r)} />
						</div>
						{r.code} - {r.name}
					</div>
				);
			});
			setVisualJustifications(v);
		}
	}, [dataJustifications]);

	useEffect(() => {
		getDataCausali();
	}, []);

	const [showAddNewElement, setShowAddNewElement] = useState(false);
	const [newElementData, setNewElementData] = useState(null);

	useEffect(() => {
		if (newElementData && !newElementData.close) {
			setShowAddNewElement(true);
		} else {
			if (newElementData !== null) {
				setShowAddNewElement(false);
				setNewElementData(null);
			}
		}
	}, [newElementData]);

	const addNewJustification = () => {
		console.log('Aggiungo nuovo Giustificativo');
		const parameters = {
			code: {
				value: '',
				isValid: false,
				el: 'input',
				type: 'text',
				label: 'Codice',
				validator: [VALIDATOR_REQUIRE()],
				initValue: '',
				initIsValid: false,
				width: 'auto',
			},
			name: {
				value: '',
				isValid: false,
				el: 'input',
				type: 'text',
				label: 'Nome',
				validator: [VALIDATOR_REQUIRE()],
				initValue: '',
				initIsValid: false,
				width: 'auto',
			},
			description: {
				value: '',
				isValid: false,
				el: 'textarea',
				type: 'textarea',
				label: 'Descrizione uso',
				validator: [VALIDATOR_NO()],
				initValue: '',
				initIsValid: false,
				width: 'auto',
			},
		};

		const action = async data => {
			const newEl = await sendRequest(
				`attSettings/createJustificationCode`,
				'POST',
				{
					code: data.code.value,
					name: data.name.value,
					description: data.description.value,
				},
				{ 'Content-Type': 'application/json' }
			);
			setDataJustifications(prev => {
				const d = [...prev, newEl];
				d.sort((a, b) => {
					let textA = a.code;
					let textB = b.code;
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				});
				return d;
			});
		};

		setNewElementData({
			parameters: parameters,
			action: action,
			title: 'Nuovo Giustificativo',
			mode: 'ADD',
			close: false,
			setNewElementData: setNewElementData,
		});
	};

	const editJustification = el => {
		console.log('Modifico Giustificativo');
		const isActiveStatus = Boolean();
		const parameters = {
			code: {
				value: el.code,
				isValid: true,
				el: 'input',
				type: 'text',
				label: 'Codice',
				validator: [VALIDATOR_REQUIRE()],
				initValue: el.code,
				initIsValid: true,
				width: 'auto',
			},
			name: {
				value: el.name,
				isValid: true,
				el: 'input',
				type: 'text',
				label: 'Nome',
				validator: [VALIDATOR_REQUIRE()],
				initValue: el.name,
				initIsValid: true,
				width: 'auto',
			},
			description: {
				value: el.description,
				isValid: true,
				el: 'textarea',
				type: 'textarea',
				label: 'Descrizione uso',
				validator: [VALIDATOR_NO()],
				initValue: el.description,
				initIsValid: true,
				width: 'auto',
			},
			isActive: {
				value: el.isActive,
				isValid: true,
				el: 'checkbox',
				type: 'checkbox',
				label: 'Attivo',
				validator: [VALIDATOR_NO()],
				initValue: el.isActive,
				initIsValid: true,
				width: 'auto',
				click: true,
			},
			delete: {
				value: false,
				isValid: true,
				el: 'checkbox',
				type: 'checkbox',
				label: 'Elimina codice',
				validator: [VALIDATOR_NO()],
				initValue: false,
				initIsValid: true,
				width: 'auto',
				click: true,
			},
		};

		const action = async data => {
			// console.log(el);
			console.log({ data });
			const updatedEl = await sendRequest(
				`attSettings/editJustificationCode`,
				'POST',
				{
					id: el._id,
					code: data.code.value,
					name: data.name.value,
					description: data.description.value,
					isActive: Boolean(data.isActive.value),
					delete: Boolean(data.delete.value),
				},
				{ 'Content-Type': 'application/json' }
			);
			if (updatedEl.code === 'deleted') {
				setDataJustifications(prev => {
					const newData = prev.filter(el => {
						return el._id !== updatedEl._id;
					});
					return newData;
				});
			} else {
				setDataJustifications(prev => {
					const ix = prev.findIndex(el => {
						return el._id === updatedEl._id;
					});
					const newArray = [...prev];
					newArray[ix] = updatedEl;
					return newArray;
				});
			}
		};
		setNewElementData({
			parameters: parameters,
			action: action,
			title: 'Modifica Giustificativo',
			mode: 'EDIT',
			close: false,
			setNewElementData: setNewElementData,
		});
	};

	const addNewElement = () => {
		const newElementForm = <AddNewSetting data={newElementData} />;

		return ReactDom.createPortal(
			newElementForm,
			document.getElementById('modal-hook')
		);
	};

	return (
		<React.Fragment>
			{isLoading && <LoadingSpinner asOverlay />}
			{error && <ErrorModal error={error} onClear={clearError} />}
			{showAddNewElement && addNewElement()}
			<div className={classes.wrapper}>
				<div className={classes.header}>
					Giustificativi
					<div className={`${classes.header__add}`}>
						<IconButton text={'add_circle'} action={addNewJustification} />
					</div>
				</div>
				<div className={classes.list}>{visualJustifications}</div>
			</div>
		</React.Fragment>
	);
}

export default Giustificativo;
