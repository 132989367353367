export const draw = (canvasId, lineColor, lineWidth) => {
	window.requestAnimFrame = (function (callback) {
		return (
			window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.oRequestAnimationFrame ||
			window.msRequestAnimaitonFrame ||
			function (callback) {
				window.setTimeout(callback, 1000 / 60);
			}
		);
	})();

	const canvas = document.getElementById(canvasId);

	if (canvas) {
		const ctx = canvas.getContext('2d');
		ctx.strokeStyle = lineColor || '#222222';
		ctx.lineWidth = lineWidth || 2;

		let drawing = false;
		let mousePos = {
			x: 0,
			y: 0,
		};
		let lastPos = mousePos;

		//Mouse input
		canvas.addEventListener(
			'mousedown',
			e => {
				drawing = true;
				lastPos = getMousePos(canvas, e);
			},
			false
		);

		canvas.addEventListener(
			'mouseup',
			e => {
				drawing = false;
			},
			false
		);

		canvas.addEventListener(
			'mousemove',
			e => {
				mousePos = getMousePos(canvas, e);
			},
			false
		);

		// Touch input
		canvas.addEventListener(
			'touchstart',
			e => {
				'';
			},
			{ passive: true }
		);

		canvas.addEventListener(
			'touchmove',
			e => {
				var touch = e.touches[0];
				var me = new MouseEvent('mousemove', {
					clientX: touch.clientX,
					clientY: touch.clientY,
				});
				canvas.dispatchEvent(me);
			},
			{ passive: true }
		);

		canvas.addEventListener(
			'touchstart',
			e => {
				mousePos = getTouchPos(canvas, e);
				var touch = e.touches[0];
				var me = new MouseEvent('mousedown', {
					clientX: touch.clientX,
					clientY: touch.clientY,
				});
				canvas.dispatchEvent(me);
			},
			{ passive: true }
		);

		canvas.addEventListener(
			'touchend',
			e => {
				var me = new MouseEvent('mouseup', {});
				canvas.dispatchEvent(me);
			},
			false
		);

		const getMousePos = (canvasDom, mouseEvent) => {
			var rect = canvasDom.getBoundingClientRect();
			return {
				x: mouseEvent.clientX - rect.left,
				y: mouseEvent.clientY - rect.top,
			};
		};

		const getTouchPos = (canvasDom, touchEvent) => {
			var rect = canvasDom.getBoundingClientRect();
			return {
				x: touchEvent.touches[0].clientX - rect.left,
				y: touchEvent.touches[0].clientY - rect.top,
			};
		};

		const renderCanvas = () => {
			if (drawing) {
				ctx.moveTo(lastPos.x, lastPos.y);
				ctx.lineTo(mousePos.x, mousePos.y);
				ctx.stroke();
				lastPos = mousePos;
			}
		};

		// Prevent scrolling when touching the canvas
		document.body.addEventListener(
			'touchstart',
			e => {
				if (e.target == canvas) {
					e.preventDefault();
				}
			},
			false
		);
		document.body.addEventListener(
			'touchend',
			e => {
				if (e.target == canvas) {
					e.preventDefault();
				}
			},
			false
		);
		document.body.addEventListener(
			'touchmove',
			e => {
				if (e.target == canvas) {
					e.preventDefault();
				}
			},
			false
		);

		const drawLoop = () => {
			window.requestAnimFrame(drawLoop);
			renderCanvas();
		};
		drawLoop();
	}

	// Set up the UI
	// var sigText = document.getElementById('sig-dataUrl');
	// var sigImage = document.getElementById('sig-image');
	// var clearBtn = document.getElementById('sig-clearBtn');
	// var submitBtn = document.getElementById('sig-submitBtn');

	// //TODO: Funzioni da esportare singolarmente
	// clearBtn.addEventListener(
	// 	'click',
	// 	e => {
	// 		clearCanvas();
	// 		sigText.innerHTML = 'Data URL for your signature will go here!';
	// 		sigImage.setAttribute('src', '');
	// 	},
	// 	false
	// );
	// submitBtn.addEventListener(
	// 	'click',
	// 	e => {
	// 		var dataUrl = canvas.toDataURL();
	// 		sigText.innerHTML = dataUrl;
	// 		sigImage.setAttribute('src', dataUrl);
	// 	},
	// 	false
	// );
};
export const clearCanvas = canvasId => {
	const canvas = document.getElementById(canvasId);
	if (canvas) {
		canvas.width = canvas.width;
	}
};
