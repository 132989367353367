import React from 'react';

import classes from './AddNewSetting.module.css';

import { useForm } from '../../../../hooks/form-hook';
import Input from '../../../../utils/Inputs/Input';
import Button from '../../../../utils/Button/Button';

function AddNewSettings({ data }) {
	const [formState, inputHandler, setFormData] = useForm(data.parameters);

	const closeCard = () => {
		data.setNewElementData({ ...data, close: true });
	};

	const postData = () => {
		data.action(formState.inputs);
		closeCard();
	};

	const checkHandler = id => {
		let _val = document.getElementById(id).checked;
		inputHandler(id, _val, true);
	};

	const setInputs = () => {
		let inputs = formState.inputs;
		let keys = Object.keys(formState.inputs);
		// console.log(formState.inputs?.isActive?.value);
		// console.log(formState.inputs?.delete?.value);
		const inputsVisual = keys.map(k => {
			let i = inputs[k];
			return (
				<Input
					key={k}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
					width={i.width || ''}
					onClick={i.click && (async () => await checkHandler(k))}
				/>
			);
		});
		return inputsVisual;
	};

	const evalButtonName = () => {
		let value;
		switch (data.mode) {
			case 'ADD':
				value = 'Inserisci';
				break;

			case 'EDIT':
				value = 'Modifica';

				break;
			default:
				break;
		}

		return value;
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={closeCard} />
			<div className={classes.wrapper}>
				<div className={classes.header}>{data.title}</div>
				<div className={classes.form}>
					{setInputs()}
					{/* Attivo: {formState.inputs.isActive.value.toString()} */}
					<br />
					{/* Delete: {formState.inputs.delete.value.toString()} */}
					<div className={classes.form__buttons}>
						<Button
							clname='danger'
							onClick={closeCard}
							style={{ fontSize: 20 + 'px' }}
						>
							Annulla
						</Button>
						<Button
							clname='confirm'
							style={{ fontSize: 20 + 'px' }}
							disabled={!formState.isValid}
							onClick={postData}
						>
							{evalButtonName()}
						</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default AddNewSettings;
