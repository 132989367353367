import React, { useRef, useState } from 'react';

import classes from './StreamCamera.module.css';

import IconButton from './IconButton';

function StreamCamera({ clear }) {
	const videoRef = useRef(null);
	const streamRef = useRef(null);

	const openCamera = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			if (videoRef.current) {
				videoRef.current.srcObject = stream;
				streamRef.current = stream;
			}
		} catch (error) {
			console.error('Error accessing camera:', error);
		}
	};

	const takePhoto = () => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		// Imposta le dimensioni del canvas sulla larghezza e altezza del video
		canvas.width = videoRef.current.videoWidth;
		canvas.height = videoRef.current.videoHeight;

		// Disegna l'immagine dalla fotocamera sul canvas
		context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

		// Converti il canvas in un'immagine data URL
		const photoDataURL = canvas.toDataURL('image/png');

		// Esegui azioni con la foto, ad esempio inviarla al server o mostrarla nell'app
		console.log(photoDataURL);

		// Fermare la fotocamera e rilasciare le risorse
		stopCamera();
	};

	const stopCamera = () => {
		if (streamRef.current) {
			const tracks = streamRef.current.getTracks();
			tracks.forEach(track => track.stop());
		}
	};

	const closeCameraForm = () => {
		stopCamera();
		clear();
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={closeCameraForm} />
			<div className={classes.wrapper}>
				<button onClick={openCamera}>Apri Fotocamera</button>
				<video
					ref={videoRef}
					autoPlay
					style={{ display: 'block', margin: '10px 0' }}
				></video>
				<button onClick={takePhoto}>Scatta Foto</button>
				<button onClick={closeCameraForm}>Chiudi Fotocamera</button>
			</div>
		</React.Fragment>
	);
}

export default StreamCamera;
