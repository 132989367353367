import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import classes from './FormSignature.module.css';

import Signature from './Signature';
import Button from '../Button/Button';

function FormSignature({
	id,
	width = 620,
	height = 160,
	clName,
	sign,
	updateSign,
}) {
	const [signatureData, setSignatureData] = useState(sign || null);
	const [showSignForm, setShowSignForm] = useState(false);
	const handleShowSignForm = () => {
		setShowSignForm(!showSignForm);
	};

	const openSignatureForm = () => {
		return ReactDom.createPortal(
			<Signature
				clear={handleShowSignForm}
				width={width}
				height={height}
				setValue={setSignatureData}
				target={id}
			/>,
			document.getElementById('modal-hook-2')
		);
	};

	useEffect(() => {
		if (signatureData && signatureData !== sign) {
			const sigImage = document.getElementById(id);
			sigImage.setAttribute('src', signatureData);
			if (updateSign) {
				console.log('here');
				updateSign({ id, signatureData });
			}
		}
	}, [signatureData]);

	return (
		<React.Fragment>
			{showSignForm && openSignatureForm()}
			{signatureData ? (
				<img
					id={id}
					src={sign}
					onClick={handleShowSignForm}
					className={clName}
				/>
			) : (
				<Button clname='confirm signature' onClick={handleShowSignForm}>
					Firma
				</Button>
			)}
		</React.Fragment>
	);
}

export default FormSignature;
