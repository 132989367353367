import React from 'react';

import classes from './ContactSection.module.css';

import ContactPanel from '../ConactPanel/ContactPanel';

function ContactSection({
	contactPerson,
	contacts,
	filter,
	updatePartecipants,
	committente,
}) {
	const evalCards = () => {
		const cards = [
			<ContactPanel
				key={'contactPerson'}
				contact={{
					refContatto: committente?.refContatto,
					siteReferents: [contactPerson],
				}}
				updatePartecipants={updatePartecipants}
			/>,
		];
		contacts?.map(c => {
			if (c.siteReferents.length == 0) {
				return;
			}
			if (filter) {
				if (Boolean(c[filter[0]]) === Boolean(filter[1])) {
					cards.push(
						<ContactPanel
							key={c._id}
							contact={c}
							updatePartecipants={updatePartecipants}
						/>
					);
				}
			} else {
				cards.push(
					<ContactPanel
						key={c._id}
						contact={c}
						updatePartecipants={updatePartecipants}
					/>
				);
			}
		});

		return cards;
	};

	return (
		<div className={classes.section}>
			<div className={classes.panelsWrapper}>{evalCards()}</div>
		</div>
	);
}

export default ContactSection;
