export const formatTextNewLine = (text, id) => {
	const list = text.split('\n');
	const textBrakes = list.map((t, i) => {
		return (
			<p key={id + '-' + i}>
				{t}
				<br />
			</p>
		);
	});

	return textBrakes;
};
