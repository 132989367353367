import React from 'react';

import classes from './Report_SiteContactPanel.module.css';

import Report_SiteContactCard from '../Report_SiteContactCard/Report_SiteContactCard';

function Report_SiteContactPanel({ c }) {
	const evalContactsCard = () => {
		const visuals = c?.siteReferents?.map(c => {
			return (
				<Report_SiteContactCard
					key={'R_SCC_' + c._id}
					contact={c.refContatto}
					role={c.refSiteRole?.name}
				/>
			);
		});

		return visuals;
	};
	return (
		<div className={classes.wrapper}>
			<div className={classes.company}>
				<p className={classes.companyName}>{c?.refContatto?.fullname}</p>
				<p>{c?.refContatto?.address}</p>
				<p>Tel: {c?.refContatto?.phoneNumber}</p>
				<p>Mail: {c?.refContatto?.email}</p>
			</div>
			{c.siteReferents.length > 0 && (
				<div className={classes.contacts}>{evalContactsCard()}</div>
			)}
			{c.subappaltatrice && c.siteReferents.length == 0 && (
				<div className={classes.subappWrapper}>
					<p className={classes.header}> Subappatlo</p>
					<div className={classes.companyName}>{c?.appaltatore?.fullname}</div>
				</div>
			)}
		</div>
	);
}

export default Report_SiteContactPanel;
