import React, { useState, useEffect } from 'react';

import classes from './CantieriSetup.module.css';

import SiteRoles from './SiteRoles/SiteRoles';

import { useHttpClient } from '../../../hooks/http-hooks';
import ErrorModal from '../../../utils/ErrorModal';
import LoadingSpinner from '../../../utils/LoadingSpinner';

function CantieriSetup() {
	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	/**
	 * >>>>>> Ruoli
	 */

	const [rolesList, setRolesList] = useState([]);

	const getRolesData = async () => {
		const d = await sendRequest('settings/sites/roles/list');

		setRolesList(d);
	};

	const postRole = async data => {
		const d = await sendRequest(
			'settings/sites/role/edit',
			'POST',
			{ ...data },
			{ 'Content-Type': 'application/json' }
		);

		setRolesList(prev => {
			const ix = prev.findIndex(el => {
				return el._id === d._id;
			});
			const newArray = [...prev];
			if (ix < 0) {
				newArray.push(d);
				newArray.sort((a, b) => {
					let textA = `${a.forPerson ? 'P' : 'A'}_${a.name}`;
					let textB = `${b.forPerson ? 'P' : 'A'}_${b.name}`;
					console.log(textA);
					console.log(textB);
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				});
			} else {
				newArray[ix] = d;
			}

			return newArray;
		});
	};

	/**<<<<<<<< */

	useEffect(() => {
		getRolesData();
	}, []);

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			<div className={classes.wrapper}>
				<SiteRoles key={0} list={rolesList} postData={postRole} />
			</div>
		</React.Fragment>
	);
}

export default CantieriSetup;
