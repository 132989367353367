import React from 'react';

import classes from './SolvedReportLine.module.css';

function SolvedReportLine({ line }) {
	return (
		<div key={line._id} className={`${classes.wrapper}`}>
			<div className={classes.line}>
				<p className={classes.title}>Prescrizione</p>
				<p>{line.prescription}</p>
			</div>
			<div className={classes.line}>
				<p className={classes.title}>Riscontro</p>
				<p>{line.resolution}</p>
			</div>
		</div>
	);
}

export default SolvedReportLine;
