import { createContext, useState } from 'react';

export const ReportCxt = createContext({
	site: null,
	setSite: null,
	reportHeader: null,
	setReportHeader: null,
	reportLines: null,
	setReportLines: null,
});
